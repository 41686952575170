.chatbotLeftColumn {
  max-width: 350px;
}

.chatbotTickImage {
  vertical-align: middle;
  img {
    object-fit: contain;
  }
}

.mobileFrame {
  position: relative;
  left: -196px;
}

.screen {
  z-index: 2;
  position: absolute;
  top: 17px;
  left: 85px;
}

.device {
  z-index: 3;
  position: absolute;
  left: 78px;
}

.statusArea {
  position: relative;
  top: 490px;
  right: 20px;
  max-width: 200px;
}

.button {
  margin-left: 6rem;
}

.spinner {
  margin-left: 1.5rem;
  padding-left: 50px;
}

.modal {
  height: fit-content;
  font-size: 1.4rem;
}

.Polaris-TextField--multiline > .Polaris-TextField__Input {
  max-height: 100px !important;
}

@media all and (max-width: 630px) {
  .chatbotLeftColumn {
    max-width: 100%;
  }

  .mobileFrame {
    position: relative;
    left: -196px;
    top: 40px;
  }

  .statusArea {
    position: relative;
    top: 510px;
    right: 20px;
    max-width: 90%;
  }
}

@media all and (min-width: 750px) and (max-width: 850px) {
  .chatbotLeftColumn {
    max-width: 90%;
  }

  .mobileFrame {
    position: relative;
    left: -196px;
    top: 40px;
  }

  .statusArea {
    position: relative;
    top: 510px;
    right: 20px;
    max-width: 90%;
  }
}

@media all and (min-width: 1000px) {
  .chatbotLeftColumn {
    max-width: 380px;
  }

  .statusArea {
    position: relative;
    top: 490px;
    right: 20px;
    max-width: 250px;
  }
}
