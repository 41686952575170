.logo-div {
  text-align: center;
}
.sl-logo {
  width: 25rem;
  padding: 2rem 0rem;
}
.onboarding-main {
  padding: 5rem;
  background-color: #eefae7;
  height: 100vh;
}
.onboarding-container {
  padding: 5rem;
  display: flex;
}
.onboarding-logo {
  height: 45rem;
}
.onbrd-left {
  min-width: 50%;
}
.onbrd-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.onbrd-title {
  font-size: 3rem;
  font-weight: 600;
  line-height: 3rem;
}
.onbrd-button button {
  margin: 1rem 0rem;
  background: #5ec127;
  border: 1px solid #5ec127 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  color: #fff;
  cursor: pointer;
}
.onbrd-button button:hover {
  background: #76d841;
}
.onbrd-button span {
  font-size: 1.8rem;
}
.onbrd-descp {
  font-size: 1.5rem;
  line-height: 2rem;
}

// Features CSS
.fc-title {
  text-align: center;
  padding: 3rem;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3rem;
}
.features-container {
  display: flex;
  justify-content: space-around;
}
.fc-left {
  padding: 2rem 1rem;
}
.fc-card-container {
  margin: 0rem 20rem;
}
.fc-feature {
  display: flex;
  margin: 2rem;
}
.fc-name-descp {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
  gap: 0.2rem;
}
.fc-selected-name {
  font-size: 1.6rem;
  font-weight: 600;
}
.fc-selected-descp {
  font-size: 1.3rem;
}
.fc-button {
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}
.fc-button button {
  margin: 1rem 0rem;
  background: #5ec127;
  border: 1px solid #5ec127 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  color: #fff;
  cursor: pointer;
  width: 30%;
}
.fc-button button:hover {
  background: #76d841;
}
.fc-selected-icon path {
  fill: #5ec127;
}

@media all and (max-width: 600px) {
  .sl-logo {
    padding: 0rem;
  }
  .onboarding-main {
    padding: 2rem;
  }
  .onboarding-container {
    padding: 2rem;
    flex-direction: column;
  }
  .onboarding-logo {
    height: 32rem;
  }
  .onbrd-right {
    padding: 2rem;
  }
  .onbrd-title {
    font-size: 2rem;
    line-height: 2rem;
  }
  .onbrd-descp {
    padding: 1rem 0rem;
  }
  .fc-card-container {
    margin: 0rem;
  }
  .fc-right img {
    height: 25rem;
  }
}
@media all and (max-width: 760px) {
  .features-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .fc-card-container {
    margin: 0rem 10rem;
  }
}
@media (min-width: 980px) and (max-width: 1200px) {
  .onboarding-logo {
    height: 35rem;
  }
  .fc-card-container {
    margin: 0rem;
  }
}

@media (min-width: 900px) and (max-width: 980px) {
  .onboarding-logo {
    height: 30rem;
  }
  .fc-card-container {
    margin: 0rem 5rem;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .sl-logo {
    padding: 0rem;
  }
  .onboarding-main {
    padding: 2rem;
  }
  .onboarding-container {
    padding: 2rem;
    flex-direction: column;
  }
  .onbrd-left {
    display: flex;
    justify-content: center;
  }
  .onboarding-logo {
    height: 35rem;
  }
  .onbrd-right {
    padding: 2rem;
  }
  .onbrd-title {
    font-size: 2rem;
    line-height: 2rem;
  }
  .onbrd-descp {
    padding: 1rem 0rem;
  }
  .fc-card-container {
    margin: 0rem 5rem;
  }
}
