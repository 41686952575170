.unsub {
    background-color: #f4f6f8;
    height: 100%;

    :global{
        .Polaris-Layout__Section{
            margin-left: 0px;
        }
    }
}

.unsubContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.unsubMsgContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: rgb(172, 241, 172);
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.unsubBtnContainer{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 25px;
}

.unsubBtn{
    text-decoration: underline;
    cursor: pointer;
}

.errorContainer{
    display: flex;
    flex-direction: row;
    background-color: lightcoral;
    padding: 10px 40px;
    align-items: center;
    justify-content: center;
}

.powered-by {
    text-align: center;
    margin-top: 10rem;
}

.logo {
    width: 15rem;
    height: 3rem;
}