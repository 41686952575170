@import "../../../assets/css/variables.scss";

.basic-feature-card {
  .Polaris-Heading {
    color: black;
    text-align: left;
    padding-top: 8px;
    font-size: 16px;
    line-height: 27px;
  }
  .basic-tile-feat {
    min-height: 20rem;
    position: relative;
    .absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .gen-card-feat {
    box-shadow: NONE !important;
    background-color: white !important;
  }
  .feat-content {
    color: black;
    margin-left: 2rem;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
  }
  .iconCSS {
    text-align: left;
    position: relative;
    top: 45px;
    left: 15px;
  }
}
