#content-wrapper {
  background-color: #ffffff;
  padding: 20px 10px;
  width: 300px;
  border-radius: 0.5rem;
}

.custom-user-group-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}
.create-user-group {
  margin-top: 20px;
}
.create-user-group .Polaris-FormLayout__Item {
  margin-left: 0px;
}
.create-user-group .condition-set .Polaris-Stack__Item {
  margin-top: 0px !important;
}
.template .Polaris-Button.add-condition-btn {
  min-width: 14rem;
}
.create-user-group .condition-set .Polaris-Stack {
  padding: 15px;
}
.condition-set-section {
  color: #777 !important;
}
.Polaris-Tooltip-TooltipOverlay__Content {
  color: black !important;
  background-color: #fff !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.inline-input .Polaris-Select__Backdrop,
.inline-input .Polaris-TextField__Backdrop {
  border-bottom: 2px solid var(--p-border-subdued) !important;
  border: none;
  background-color: transparent !important;
  min-width: 20%;
}
.inline-input .Polaris-Select__Content {
  padding-left: 0px;
}
.flex-box {
  display: flex;
  grid-gap: 15px;
  gap: 0px;
  align-items: center;
}
.list-unstyled ul {
  padding: 0;
  list-style-type: none;
}
.testing {
  color: red;
}

.textField .Polaris-InlineError {
  position: absolute;
}

ul.tooltip-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    &:not(:last-child) {
      padding-bottom: 8px;
    }
  }
}

.conditionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.card {
  width: auto;
  background: #f9f9f9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 3px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.addConditionBtnContainer {
  display: flex;
  justify-content: flex-end;
}
.andOrContainer {
  border: 1px solid rgb(201, 201, 201);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  border-radius: 20px;
  color: #5dc001;
}
.andContainer {
  padding: 4px 15px;
  cursor: pointer;
}
.orContainer {
  padding: 4px 5px;
  cursor: pointer;
}
.andOrContainerActive {
  background-color: #5dc001;
  color: #f5f5f5;
  border-radius: 12px;
}
.deleteContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }
}
.deleteIcon {
  cursor: pointer;
}
