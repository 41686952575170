.outerContainerTable {
  max-width: 102rem;
  margin: 0 auto;
}

.searchFilter {
  padding-top: 0.5rem;
}

@media (max-width: 600px) {
  .tagsButtonContainer {
    flex-direction: column;
  }
}
@media (max-width: 1058px) {
  .templatesButtonContainer {
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .templatesButtonContainer {
    flex-direction: row;
    justify-content: center !important;
  }
  .templatesContainer {
    display: block;
    margin-left: 0;
  }
  .textContentCard {
    width: 100% !important;
  }
  .buttonsOuterContainer {
    width: 100% !important;
  }
}
