.basic-error-modal {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 10px;
  overflow: hidden !important;
  .errorModalHeading {
    line-height: 60px;
    color: #5ec127;
    font-weight: 600;
    font-size: 200%;
  }

  .errorModalContent {
    font-weight: 300;
    margin-top: 3%;
    position: relative;
    padding: 0% 10%;
  }

  .errorModalButtonCss {
    padding: 5% 40%;
    background: #5ec127 !important;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 16px;
    border: solid 1px;
    margin-top: 0;
    margin-bottom: 50px;
  }

  .errorModalFooter {
    position: relative;
    left: 40%;
    top: 45px;
  }

  .errorModalHeading img {
    width: 45px;
    height: 45px;
    position: relative;
    right: 2%;
    top: 13px;
  }
}

@media all and (max-width: 610px) {
  .basic-error-modal {
    .errorModalFooter {
      position: relative;
      left: 28%;
      top: 45px;
    }
  }
}
