@import "../../../assets/css/variables.scss";

.basic_info_plans {
  min-width: 270px;
  .Polaris-Heading {
    color: #5ec127;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  a {
    color: #5ec128 !important;
  }
  .min-height-4 {
    min-height: 4rem;
  }
  .basic-tile {
    height: 65rem;
    position: relative;
    max-width: 275px;
    right: 10px;
    .absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .align-at-top {
      top: -1px;
      border-radius: 0;
    }
    .align-at-bottom {
      position: absolute !important;
      bottom: 5px !important;
      left: 45%;
    }
    .plan-recommend-btn {
      border-radius: 4px !important;
      padding: 0.2rem 1.8rem !important;
      min-height: 2.4rem !important;
      pointer-events: none;
    }

    .plan-btn-disabled {
      pointer-events: none;
      color: grey !important;
      background-color: white !important;
      border-color: grey !important;
      cursor: not-allowed;
    }
    .button-padding-30 {
      padding-left: 30% !important;
      padding-right: 30% !important;
    }
    .card-heading {
      font-size: 16px !important;
    }
  }

  .planCardNew {
    width: 33%;
    position: relative;
    padding: 0rem 0.5rem 0rem 0.5rem;

    .absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .align-at-top {
      top: 7px;
      border-radius: 0;
      left: 30%;
      font-size: 1em;
      font-weight: 600;
    }
    .align-at-bottom {
      position: absolute !important;
      bottom: 5px !important;
      left: 45%;
    }
    .plan-recommend-btn-new {
      border-radius: 4px !important;
      padding: 0.85rem !important;
      min-height: 2.4rem !important;
      pointer-events: none;
    }

    .plan-btn-disabled-new {
      pointer-events: none;
      color: grey !important;
      background-color: white !important;
      border-color: grey !important;
      cursor: not-allowed;
    }
    .button-padding-30 {
      padding-left: 30% !important;
      padding-right: 30% !important;
    }
    .card-heading {
      font-size: 16px !important;
    }
  }

  .planCardW35 {
    width: 40rem;
    position: relative;
    padding: 0rem 0.5rem 0rem 0.5rem;

    .absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .align-at-top {
      top: 7px;
      border-radius: 0;
      left: 20%;
      font-size: 1em;
      font-weight: 600;
    }
    .align-at-bottom {
      position: absolute !important;
      bottom: 5px !important;
      left: 45%;
    }
    .plan-recommend-btn-new {
      border-radius: 4px !important;
      padding: 0.85rem !important;
      min-height: 2.4rem !important;
      pointer-events: none;
    }

    .plan-btn-disabled-new {
      pointer-events: none;
      color: grey !important;
      background-color: white !important;
      border-color: grey !important;
      cursor: not-allowed;
    }
    .button-padding-30 {
      padding-left: 30% !important;
      padding-right: 30% !important;
    }
    .card-heading {
      font-size: 16px !important;
    }
  }

  .short-tile {
    position: relative;
    height: 22em;
    aspect-ratio: 4/5;
    .absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .align-at-top {
      top: 0;
      border-radius: 0;
    }
    .align-at-bottom {
      position: absolute !important;
      bottom: 5px !important;
      left: 45%;
    }
    .plan-recommend-btn {
      border-radius: 4px !important;
      padding: 0.2rem 1.8rem !important;
      min-height: 2.4rem !important;
      pointer-events: none;
    }

    .plan-btn-disabled {
      pointer-events: none;
      color: grey !important;
      background-color: white !important;
      border-color: grey !important;
      cursor: not-allowed;
    }
    .button-padding-30 {
      padding-left: 30% !important;
      padding-right: 30% !important;
    }
    .card-heading {
      font-size: 16px !important;
    }
  }

  .plan-rec-card {
    background-color: white !important;
    border-color: #5ec128 !important;
    border-style: solid !important;
    border-width: thin;
    border-radius: 8px !important;
  }
  .plan-gen-card {
    background-color: #f7f9f9 !important;
    border-radius: 8px !important;
  }
  .iconCSS {
    text-align: right;
    position: relative;
    bottom: 30px;
    right: 10px;
  }
  .plan_price_1 {
    margin-top: -10rem;
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    /* identical to box height */

    color: #4b4b4b;
  }

  .plan_price_2 {
    margin-top: -10rem;
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4b4b4b;
  }

  .plan_price_1_noPrice {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #4b4b4b;
    margin-top: -90px;
  }

  .plan_price_month_1 {
    position: relative;
    left: 2px;
    bottom: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;  /* identical to box height */
    color: #aeaeae;
  }

  .plan_price_month_2 {
    position: relative;
    left: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;  /* identical to box height */
    color: #aeaeae;
  }

  .plan_price_1_strike_through {
    text-align: center;
    padding: 1rem 0;
    color: #8b8b8b;
    font-weight: normal;
    padding-top: 0;
    font-size: 1.8rem;
  }
  .plan_price_month_1_strike_through {
    text-align: center;
    color: gray;
    font-weight: normal;
  }

  .sup {
    vertical-align: super;
    font-size: large;
    position: relative;
    top: 6px;
  }

  .sup2 {
    vertical-align: super;
    font-size: large;
    position: relative;
  }

  .Polaris-Icon__Svg {
    fill: white !important;
  }

  .catchLineCSS {
    padding: 8px;
    padding-right: 20px;
    background-color: #ebf9e4;
    margin-left: 4rem;
    margin-bottom: 4rem;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5ec127;
    position: absolute;
    right: 5%;
    width: 90%;
    bottom: 20%;

    .tc-icon {
      float: right;
      font-size: 10px;
      margin-right: -10px;
    }
  }
}

.detailed_plan_info {
  .header_only {
    font-weight: bold;
  }
  .plan_price_1 {
    text-align: center;
    padding-top: 1rem;
    color: $primary-color;
    font-weight: bold;
    font-size: large;
  }
  .plan_price_month_1 {
    text-align: center;
    padding-top: 1rem;
    color: $primary-color;
    font-size: small;
  }

  .sup {
    vertical-align: super;
    font-size: small;
  }
  .border-2 {
    border-width: 1.5px;
  }
  .Polaris-List {
    list-style-type: none !important;
    padding-left: 4px !important;
    margin-bottom: 100px;
  }
  .Polaris-List li::before {
    position: relative;
    left: 15px;
    content: "" !important;
    color: $primary-color !important;
    padding-right: 4rem;
    margin-left: -4rem;
    vertical-align: middle;
    background: url("../../../assets/icons/tick.svg") no-repeat !important ;
  }
  .Polaris-DataTable__Cell {
    white-space: normal !important;
  }
  .highlight {
    background-color: #f1fae8;
  }
  .includedTextCss {
    margin-left: -2rem !important;
    margin-top: 2rem !important;
    position: relative;
    top: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #050505;
  }
  .includedTextCssShort {
    margin-left: -2rem !important;
    position: relative;
    top: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #050505;
  }
  .featuresPoints {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #696969;
  }
}

.close-btn {
  border-radius: 50% !important;
  background-color: black !important;
  margin-left: -2rem !important;
  margin-top: -1rem !important;
  padding: 0.8rem !important;
  height: 2.8rem !important;
  position: absolute !important;
  right: 0;
  .Polaris-Icon {
    width: 1.2rem !important;
  }
  .Polaris-Icon--colorBase svg {
    fill: #fff !important;
  }
}

.modal-container {
  max-width: 60rem !important;
  .Polaris-Modal-Footer {
    border: 0 !important;
    align-self: center !important;
    justify-content: center !important;
  }
  .d-content {
    display: contents;
  }
  .Polaris-Modal-CloseButton::after {
    pointer-events: unset;
  }
}

.strike-through {
  text-decoration: line-through;
}
.pricing-color-strike-through {
  color: #8b8b8b;
  margin-right: 0.4rem;
}

.planButtonCss {
  width: 95%;
  height: 40px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  color: #5ec127;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border: solid 1px #5ec127 !important;
  margin-bottom: 1.5rem;
  bottom: 15px !important;
  left: 5%;
}

.planButtonCssExtension {
  position: relative;
  width: 95%;
  height: 40px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  color: #5ec127;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border: solid 1px #5ec127 !important;
  margin-bottom: 1.5rem;
  bottom: 15px !important;
  left: 2.5%;
}

.iconSmall {
  text-align: left;
  position: relative;
  bottom: -38px;
  left: 10%;
  width: 0;
}

// @media all and (max-width: 650px) {
//   .basic_info_plans {
//     margin: auto;
//     width: 282px !important;
//     min-width: 270px;
//   }
// }

.tc {
  color: #5ec127;
  margin-left: 20px;
}

.additionalCharges {
  color: #5ec127;
  margin-left: 3rem;
}

.planBottomDiv {
  padding-top: 5rem;
  padding-left: 1rem;
}

.planBottomNote {
  color: #6D7175;
  a {
    color: #5EC127 !important;
    text-decoration: underline;
  }
  ol {
    margin: 0px ;
    padding: 0px;
    padding-left: 15px;
  }
}

.cardHeightNew {
  margin-top: 20px;
}

.planBottomDivNew {
  margin-top: 20px;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;  
}

.planBottomNoteContainer {
  padding: 1rem;
  margin: 0px 10px 10px 10px;
}

.catchLineCSSNew {
  background-color: #ebf9e4;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5ec127;
  padding: 5px 15px 5px 15px;
  margin: 10px 15px 10px 15px;
}

.detailed_plan_info_new {
  margin: 10px 15px 10px 15px;

  .features_line_new {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  .featureHeader {
    font-weight: 500;
    color: #5ec127;
    margin-bottom: 10px;
    padding-left: 5px;
  }
  .header_only {
    font-weight: bold;
  }
  .plan_price_1 {
    text-align: center;
    padding-top: 1rem;
    color: $primary-color;
    font-weight: bold;
    font-size: large;
  }
  .plan_price_month_1 {
    text-align: center;
    padding-top: 1rem;
    color: $primary-color;
    font-size: small;
  }
  .sup {
    vertical-align: super;
    font-size: small;
  }
  .border-2 {
    border-width: 1.5px;
  }
  .Polaris-List {
    list-style-type: none !important;
    padding-left: 4px !important;
  }

  .Polaris-DataTable__Cell {
    white-space: normal !important;
  }

  .highlight {
    background-color: #f1fae8;
  }
  .includedTextCss {
    margin-left: -2rem !important;
    margin-top: 2rem !important;
    position: relative;
    top: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #050505;
  }
  .includedTextCssShort {
    margin-left: -2rem !important;
    position: relative;
    top: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #050505;
  }
  .featuresPoints {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #696969;
  }
}

.close-btn {
  border-radius: 50% !important;
  background-color: black !important;
  margin-left: -2rem !important;
  margin-top: -1rem !important;
  padding: 0.8rem !important;
  height: 2.8rem !important;
  position: absolute !important;
  right: 0;
  .Polaris-Icon {
    width: 1.2rem !important;
  }
  .Polaris-Icon--colorBase svg {
    fill: #fff !important;
  }
}

.planCardBadge {
  font-size: 16px;
  font-weight: 600;
}

.cardHeight {
  height: 35rem !important;
}

.plan_price_1_new {
  margin-top: -10rem;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #4b4b4b;
  padding-left: 26px;
}

.planButtonCssNew {
  width: 85%;
  height: 40px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  color: #5ec127;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border: solid 1px #5ec127 !important;
}

.featureListContainer {
  margin-bottom: 5px;
}

.iconCSSNew {
  text-align: right;
  position: relative;
  bottom: 15px;
  right: 10px;
}

.planFreePlus{
  font-weight: 500;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 5px;
  margin-top: 10px;
}