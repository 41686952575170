.featureContainer {
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 33px;
  width: 100%;
}
.heading {
  margin-top: 25px;
  font-family: "Rubik",sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #202223;
}
.subheading {
  margin-top: 10px;
  font-family: "Rubik", sans-serif ;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #6d7175;
  height: auto;
}
.buttonContainer {
  margin-top: 30px;
}
.featureButton {
  font-family: "Rubik", sans-serif ;
  font-style: normal;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #5ec127;
  border: 1px solid #5ec127;
  background: #ffffff;
  border-radius: 5px;
}
.featureButton:hover {
  cursor: pointer;
  background-color: #5ec127;
  color: #ffffff;
}
.featurePageContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.featureRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
@media (min-width: 1024px) {
  .featureRow {
    flex-direction: row;
  }
  .featureContainer {
    width: 50%;
  }
  .subheading {
    height: 70px;
  }
}
.mainTitle {
  color: #202223;
  font-family: "Rubik", sans-serif ;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  margin: 15px 0px;
}
