.chat__globalCss {
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "rubik", sans-serif;
    font-weight: inherit;
  }

  a,
  a:active,
  a:hover {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-decoration: none;
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  button,
  input {
    border: none;
    background-color: transparent;
  }

  ul {
    list-style: none;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }

  .dark-theme ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.16);
  }

  .dark-theme ::-webkit-scrollbar-track {
    background-color: initial;
  }

  .storeContainer {
    height: calc(100vh - 5.6rem);
    width: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 100;
    display: flex;
    overflow: hidden;
    bottom: 0;
  }

  .storeDetailsContainer {
    background: #fbfbfb;
    min-width: 380px;
    -webkit-flex: 40% 1;
    flex: 40% 1;
    border-right: 1px solid #dadada;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    .Polaris-Card {
      background-color: #ecf9e5 !important;
      margin: 0rem !important;
      border-radius: 0rem !important;
    }
    .storeDetails {
      display: flex;
      align-items: center;
      .storeDetailsLeft {
        display: flex;
        width: 96%;
        align-items: center;
        gap: 1rem;
        .storeName {
          font-weight: 600;
          font-size: 2.5rem;
        }
      }
      .storeDetailsRight {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .Polaris-ResourceItem__Content {
      display: flex;
    }
    .userListLeft {
      width: 65%;
      margin-left: 2%;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    .userListRight {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .filterContainer {
      display: flex;
      padding: 4% 6% 4% 4%;
    }
    .Polaris-Filters {
      width: 90%;
      z-index: 1;
    }
  }

  .storeUserChatContainer {
    background: #edeeec;
    border: solid 1px #f6ecec;
    /* position: relative; */
    min-width: 300px;
    -webkit-flex: 40% 1;
    flex: 40% 1;
    border-right: 1px solid #dadada;
    -webkit-flex-direction: column;
    flex-direction: column;
    z-index: 1;
    .Polaris-Card {
      margin: 0rem !important;
      border-radius: 0rem !important;
    }
    .userChatDetails {
      display: flex;
      align-items: center;
    }
    .userChatDetailsLeft {
      display: flex;
      width: 98%;
      align-items: center;
      gap: 1rem;
      .userInfo {
        display: flex;
        flex-direction: column;
      }
    }
    .userChatDetailsRight {
      display: flex;
      align-items: center;
    }
    .userChatTag {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }

  .pt5 {
    padding-top: 5px;
  }

  .wid40 {
    width: 40%;
  }

  .wid50 {
    width: 50%;
  }

  .wid80 {
    width: 80%;
  }

  .wid100 {
    width: 100% !important;
  }

  .storeUserDetailsContainer {
    padding-top: 0.5%;
    padding-right: 1%;
    padding-left: 1%;
    width: 40%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 5.6rem);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .boldText {
    font-weight: 600;
    font-size: 1.4rem;
  }

  .chat__chatList__name {
    font-weight: 600;
    font-size: 1.4rem;
    width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .chat__chatList__lastMessage {
    font-size: 1.4rem;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;
  }

  .chat__chatList__lastMessage--read {
    font-size: 1.4rem;
    font-weight: 300;
    color: #6d7175;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;
  }

  .chat__chatList__timestamp {
    font-size: 1.2rem;
    font-weight: 400;
    color: #6d7175;
  }

  .chatTagsContainer {
    padding: 0 0 2% 4%;
    display: flex;
    align-self: flex-start;
    gap: 5%;
  }

  .chatTag {
    display: flex;
    background: #f1f1f1;
    padding: 8px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
  }

  .bgGreen {
    background: #edfae5;
  }

  .whiteBg {
    background: #fff;
    padding: 4px;
    border-radius: 5px;
    margin-left: 6px;
  }

  .messageDate {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #3f3f3f;
    opacity: 0.5;
    margin: 2rem;
    display: flex;
    justify-content: center;
  }

  .incomingMessage {
    display: flex;
    gap: 2rem;
    padding: 1rem;
    margin: 1rem 0rem 1rem 0rem;
  }

  .userMessageText {
    width: 25rem;
    background: #f1f4fa;
    border-radius: 5px;
    display: flex;
    .userMessageTextContent {
      width: 80%;
      font-size: 1.5rem;
      margin-top: 1rem;
      padding-left: 5%;
    }
    .userMessageTextTime {
      width: 18%;
      font-size: 1rem;
      line-height: 1.0625rem;
      display: flex;
      align-items: flex-end;
      margin: 1rem;
    }
  }

  .outgoingMessage {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    margin: 1rem 0rem 1rem 0rem;
    flex-direction: row-reverse;
  }

  .textInputContainer {
    padding: 10px;
    height: 60px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }

  .userInputBox {
    width: 90%;
    display: flex;
    .w90 {
      width: 90%;
    }
  }

  .userInputSendButton {
    width: 10%;
    rotate: -30deg;
  }

  .userDetailsInfoContainer {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .userDetailsInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;

    .Polaris-Avatar {
      width: 6rem !important;
    }
    .closeIcon {
      position: fixed;
      right: 0.5%;
      cursor: pointer;
      background: transparent;
      opacity: 0.5;
    }
    .closeIcon::after {
      color: #579b5d !important;
    }
  }

  .dot {
    height: 2rem;
    width: 2rem;
    background-color: #5ec127;
    text-align: center;
  }

  .chatList {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 66vh;
    padding: 0% 4% 0% 2%;
  }

  .chat__overflow {
    overflow: auto !important;
  }

  .incomingMessage .chatWindowAvatar {
    width: 1rem;
    height: 1rem;
  }

  .outgoingMessage .chatWindowAvatar {
    width: 1rem;
    height: 1rem;
    margin-right: 5%;
  }

  .scrollChatWindow {
    overflow-y: scroll;
    height: 60vh;
  }

  .sendMessageTimerCss {
    position: relative;
    right: 50%;
    bottom: 17px;
    color: #6d7175;
    font-weight: 500;
    font-size: 1.3rem;
  }

  .tagGroupCss {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    right: 60px;
    max-width: 80%;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tagElementCss {
    background: #f3f4f6;
    border-radius: 11px;
    padding: 1% 5%;
    color: gray;
    font-size: 1.2rem;
  }

  .tagElementLastCss {
    background: #f3f4f6;
    border-radius: 11px;
    padding: 1% 5%;
    color: #6d7175;
  }

  .trippleDot {
    width: 20%;
    min-width: 20%;
    margin: auto;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .chatElementBox__inner {
    background-color: white;
    margin-left: 5px;
    padding: 15px;
    margin-top: 2%;
    border: solid aliceblue 1px;
    box-shadow: 0px 1px 4px lightgray;
    border-radius: 4px;
    height: 110px;
  }

  .chatElementBox__outer {
    background-color: white;
    border-radius: 10px;
    margin-left: 5px;
    margin-bottom: 15px;
    height: 110px;
  }

  .chatElementDivider {
    display: flex;
    margin-top: 1%;
  }

  .greencolor {
    color: #3e7f1a;
  }

  .mt-5 {
    margin-top: 5%;
  }

  .chatSelected {
    background-color: #59bb31;
  }

  .cp {
    cursor: pointer;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .remainingTime {
    font-size: 1.3rem;
    color: #6d7175;
    font-weight: 400;
  }

  /* To Support new design*/

  .chat {
    display: flex;
    position: relative;
  }

  .chat__body {
    min-width: 300px;
    flex: 40%;
    border-right: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    /*background: #f5f5f5;*/
  }

  .chat__bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.05;
    z-index: 1;
    background: url(../../../assets/images/bg-chat-light.png) #e4dcd4;
  }

  .chat__header,
  .chat__footer,
  .chat__date-wrapper,
  .chat__msg-group,
  .chat__encryption-msg {
    z-index: 10;
  }

  .chat__header {
    /* Needed for the options btn to stay on top */
    z-index: 20;
  }

  /* Chat Header Component  */

  .chat__avatar-wrapper {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .chat__contact-wrapper {
    flex: 1;
    position: relative;
    top: 6px;
  }

  .chat__contact-name,
  .chat__contact-desc {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .chat__contact-name {
    color: #000000;
    font-size: 1.6rem;
    margin-bottom: 2px;
    font-weight: 500;
  }

  .chat__contact-desc {
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
  }

  .chat__contact-desc-image {
    width: 1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .chat__actions {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  .chat__action {
    margin-left: 25px;
    cursor: pointer;
  }

  .chat__action:not(.options-btn) {
    display: inline-block;
  }

  .chat__action-icon {
    color: rgb(145, 145, 145);
  }

  .chat__action-icon--search {
    width: 30px;
    height: 30px;
  }

  /* End Chat Header Component  */

  /* Chat Content */

  .chat__content {
    flex: 1;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 2% 2%;
    background: #f1ece6;
  }

  .chat__date-wrapper {
    text-align: center;
    margin: 10px 0 14px;
    position: relative;
  }

  .chat__date {
    background: #e1f2fa;
    color: #000000;
    font-size: 1.2rem;
    display: inline-block;
    padding: 7px 10px;
    border-radius: 5px;
  }

  .chat__date-floater-wrapper {
    position: absolute;
    z-index: 100;
    text-align: center;
    margin: auto;
    left: 46%;
    top: 80px;
  }

  .chat__date-floater {
    background: #e1f2fa;
    display: inline-block;
    color: #000000;
    font-size: 0.9rem;
    padding: 7px 10px;
    border-radius: 5px;
  }

  .chat__encryption-msg {
    background: #fdf4c5;
    color: #000000;
    font-size: 0.77rem;
    text-align: center;
    padding: 5px 10px;
    position: relative;
    margin-bottom: 8px;
    border-radius: 5px;
    line-height: 20px;
  }

  .chat__encryption-icon {
    color: #8c866c;
    margin-right: 5px;
    margin-bottom: -1px;
  }

  .chat__msg-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    position: relative;
    padding: 5px;
  }

  .chat__msg {
    padding: 10px;
    margin-bottom: 12px;
    font-size: 1.2rem;
    color: #000000;
    width: fit-content;
    max-width: 80%;
    line-height: 20px;
    border-radius: 5px;
    position: relative;
    white-space: pre-line;
    display: flex;
  }

  .chat__msg.chat__img-wrapper {
    padding: 4px;
    width: 95%;
  }

  .chat__msg--sent {
    background: #dbf8c6;
    align-self: flex-end;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    font-size: 1.4rem;
  }

  .chat__msg--rxd {
    background: white;
    align-self: flex-start;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    font-size: 1.4rem;
  }

  .chat__msg--sent--tail {
    background: #dbf8c6;
    align-self: flex-end;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    font-size: 1.4rem;
  }

  .chat__msg--rxd--tail {
    background: white;
    align-self: flex-start;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    font-size: 1.4rem;
  }

  .chat__msg--rxd--tail::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: -8px;
    border-top: 6px solid white;
    border-right: 6px solid white;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  .chat__msg--sent--tail::after {
    content: "";
    position: absolute;
    right: -8px;
    width: 0;
    height: 0;
    top: 0;
    border-top: 6px solid #dbf8c6;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #dbf8c6;
  }

  .chat__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .chat__msg-filler {
    width: 45px;
    display: block;
    height: 1px;
    background: transparent;
  }

  .chat__msg-footer {
    position: absolute;
    display: flex;
    align-items: center;
    right: 10px;
    bottom: 1px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 1rem;
    font-weight: 500;
  }

  .chat__msg-status-icon {
    color: #b3b3b3;
    margin-left: 3px;
  }

  .chat__msg-status-icon--blue {
    color: #0da9e5;
  }

  .chat__img-wrapper .chat__msg-footer,
  .chat__img-wrapper .chat__msg-options-icon,
  .chat__img-wrapper .chat__msg-status-icon {
    color: white;
  }

  .chat__msg-options {
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 3px;
    pointer-events: none;
    transition: all 0.2s;
  }

  .chat__msg--rxd .chat__msg-options {
    background: white;
  }

  .chat__msg--sent .chat__msg-options {
    background: #dbf8c6;
  }

  .chat__img-wrapper .chat__msg-options {
    background: transparent;
  }

  .chat__msg:hover .chat__msg-options {
    opacity: 1;
    pointer-events: unset;
  }

  .chat__msg-options-icon {
    color: rgb(145, 145, 145);
    width: 20px;
    height: 20px;
  }

  /* End Chat Content */

  .chat__footer {
    position: relative;
    width: 100%;
    padding: 10px 0 10px 0;
    background: #f2f1ef;
    box-shadow: 0px -12px 20px rgba(0, 0, 0, 0.06);
  }

  .chat__scroll-btn {
    position: relative;
    left: 88%;
    bottom: 60px;
    width: 42px;
    height: 42px;
    z-index: -1;
    border-radius: 50%;
    color: rgb(145, 145, 145);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }

  /* Emoji Tray */

  .emojis__wrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 0;
    min-height: 0;
    transition: all 0.4s ease;
    background: inherit;
  }

  .emojis__wrapper--active {
    height: 40vh;
    min-height: 350px;
    transition: all 0.4s ease;
  }

  .emojis__tabs {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .emojis__tab {
    flex: 1;
    padding: 10px 5px 10px;
    text-align: center;
    position: relative;
  }

  .emojis__tab--active::after {
    content: "";
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgb(0, 150, 136);
  }

  .emojis__tab-icon {
    color: rgba(0, 0, 0, 0.32);
  }

  .emojis__tab--active .emojis__tab-icon {
    color: rgba(0, 0, 0, 0.6);
  }

  .emojis__content {
    overflow-y: scroll;
    padding: 5px 20px;
    flex: 1;
  }

  .emojis__search {
    height: 40px;
    background: #e6e6e6;
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    color: rgb(74, 74, 74);
    font-size: 0.9rem;
  }

  .emojis__search::placeholder {
    color: #989898;
  }

  .emojis__label {
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.85rem;
  }

  .emojis__grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }

  .emojis__emoji {
    margin-right: 3px;
    margin-top: 3px;
    cursor: pointer;
  }

  /* End Emoji Tray */

  /* Chat Footer Toolbar */

  .chat__input-wrapper {
    padding: 10px;
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .chat__input-icon {
    color: #919191;
    margin-left: 8px;
    margin-right: 8px;
    width: 28px;
    height: 28px;
    padding: 3px;
    border-radius: 50%;
  }

  .chat__input-icon--highlight {
    color: teal;
  }

  .chat__attach {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50px;
  }

  .chat__attach-btn {
    transform: scale(0);
    opacity: 0;
    transition: all 0.5s ease;
  }

  .chat__attach-btn:nth-of-type(1) {
    transition-delay: 0.5s;
  }

  .chat__attach-btn:nth-of-type(2) {
    transition-delay: 0.4s;
  }

  .chat__attach-btn:nth-of-type(3) {
    transition-delay: 0.3s;
  }

  .chat__attach-btn:nth-of-type(4) {
    transition-delay: 0.2s;
  }

  .chat__attach-btn:nth-of-type(5) {
    transition-delay: 0.1s;
  }

  .chat__attach--active .chat__attach-btn {
    transform: scale(1);
    opacity: 1;
  }

  .chat__attach-btn {
    margin-bottom: 10px;
  }

  .chat__input-icon--pressed {
    background: rgba(0, 0, 0, 0.1);
  }

  .chat__input {
    background: white;
    color: rgb(74, 74, 74);
    padding: 8px;
    border-radius: 5px;
    flex: 1 1;
    resize: none;
    min-height: 40px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border: 1px solid white;
    font-size: 1.5rem;
  }

  .chat__input::-webkit-scrollbar {
    display: none;
  }

  .chat__input::placeholder {
    color: rgb(153, 153, 153);
    font-size: 0.9rem;
  }

  .chat__input-wrapper-template {
    padding: 20px;
    min-height: 140px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .chat__sendTemplateButton {
    min-width: 100%;
    border: 1px solid #73d13f;
    padding: 12px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
  }
  /* End Chat Footer Toolbar */

  /* Chat Sidebar */

  .chat-sidebar {
    width: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    transition: all 0.1s ease;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .chat-sidebar--active {
    flex: 30%;
  }

  .chat-sidebar__header-icon {
    margin-right: 20px;
    color: rgb(145, 145, 145);
  }

  .chat-sidebar__heading {
    flex: 1;
    color: #000000;
    font-size: 1rem;
    margin-bottom: 2px;
  }

  .chat-sidebar__content {
    flex: 1;
  }

  .chat-sidebar__search-results {
    background: white;
    height: 100%;
    padding-top: 5pc;
    color: #00000099;
    text-align: center;
    font-size: 0.85rem;
  }

  .profile {
    background: rgb(237, 237, 237);
    padding-bottom: 2pc;
  }

  .profile__section {
    background: white;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
    padding: 10px 20px;
  }

  .profile__section--personal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
  }

  .profile__avatar-wrapper {
    width: 200px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile__name {
    flex: 1;
    color: #000000;
    font-size: 1.2rem;
    align-self: flex-start;
  }

  .profile__heading-wrapper {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .profile__heading {
    color: rgb(0, 150, 136);
    font-size: 0.85rem;
    flex: 1;
  }

  .profile__heading-icon {
    color: rgb(145, 145, 145);
  }

  .profile__media-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .profile__media {
    width: 32%;
  }

  .profile__action,
  .profile__about-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .profile__action:not(:last-of-type),
  .profile__about-item:not(:last-of-type),
  .profile__group:not(:last-of-type) {
    border-bottom: 1px solid #ebebeb;
  }

  .profile__action-left {
    flex: 1;
  }

  .profile__action-text {
    display: block;
  }

  .profile__action-text--top,
  .profile__about-item {
    font-weight: 500;
    margin-bottom: 5px;
  }

  .profile__action-text--bottom {
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.45);
  }

  .profile__section--groups {
    padding-left: 0;
    padding-right: 0;
  }

  .profile__group,
  .profile__group-heading {
    padding-left: 20px;
    padding-right: 20px;
  }

  .profile__group {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  .profile__group:hover {
    background-color: #ebebeb;
  }

  .profile__group-content {
    flex: 1;
    overflow: hidden;
  }

  .profile__group-avatar-wrapper {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .profile__group-text {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .profile__group-text--top {
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .profile__group-text--bottom {
    color: #00000099;
    font-size: 0.85rem;
    overflow: hidden;
  }

  .profile__section--danger {
    color: rgb(223, 51, 51);
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .profile__danger-icon {
    margin-right: 20px;
  }

  .profile__danger-text {
    flex: 1;
  }

  /* End  Chat Sidebar */

  @media screen and (min-width: 1301px) {
    .chat__msg {
      max-width: 65%;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    .chat__msg {
      max-width: 75%;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1000px) {
    .chat__msg {
      max-width: 85%;
    }
  }

  @media screen and (max-width: 1000px) {
    .chat-sidebar {
      transition: transform 0.1s ease;
      transform: translateX(120vw);
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
    .chat-sidebar--active {
      transform: translateX(0);
      transition: transform 0.1s ease;
    }
  }

  @media screen and (min-width: 750px) {
    .chat__msg.chat__img-wrapper {
      width: 40%;
      min-width: 300px;
      max-width: 400px;
    }
  }

  .chat__input-icon {
    color: #919191;
    margin-left: 8px;
    margin-right: 8px;
    width: 28px;
    height: 28px;
    padding: 3px;
    border-radius: 50%;
  }

  .chat__input-icon--highlight {
    color: teal;
  }

  .chat__attach {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50px;
  }

  .chat__attach-btn {
    transform: scale(0);
    opacity: 0;
    transition: all 0.5s ease;
  }

  .chat__attach-btn:nth-of-type(1) {
    transition-delay: 0.5s;
  }

  .chat__attach-btn:nth-of-type(2) {
    transition-delay: 0.4s;
  }

  .chat__attach-btn:nth-of-type(3) {
    transition-delay: 0.3s;
  }

  .chat__attach-btn:nth-of-type(4) {
    transition-delay: 0.2s;
  }

  .chat__attach-btn:nth-of-type(5) {
    transition-delay: 0.1s;
  }

  .chat__attach--active .chat__attach-btn {
    transform: scale(1);
    opacity: 1;
  }

  .chat__attach-btn {
    margin-bottom: 10px;
  }

  .chat__input-icon--pressed {
    background: rgba(0, 0, 0, 0.1);
  }

  .chat__input::placeholder {
    color: rgb(153, 153, 153);
    font-size: 0.9rem;
  }

  .underline {
    text-decoration: underline;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .pos-rel {
    position: relative;
  }

  .sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex-1 {
    flex: 1;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  .focus-visible {
    outline-color: rgba(129, 202, 231, 0.3);
  }

  .chat__chatWindow-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0px 10px 10px;
    min-height: 80px;
    z-index: 20;
    grid-gap: 8px;
    gap: 8px;
    background: #f2f1ef;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  }

  .avatar {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  /* Begin search input  */

  .search-wrapper {
    padding: 7px 10px;
    height: 50px;
    background: #f6f6f6;
    position: relative;
  }

  .search-wrapper:focus-within {
    background: white;
  }

  .search {
    background: white;
    color: rgb(74, 74, 74);
    padding-left: 60px;
    border-radius: 18px;
    width: 100%;
    height: 100%;
  }

  .search::placeholder {
    color: rgb(153, 153, 153);
  }

  .search-icons {
    color: #919191;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    overflow: hidden;
  }

  .search-icon,
  .search__back-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.8s ease;
  }

  .search-icon {
    opacity: 1;
    transition-delay: 0.3s;
  }

  .search__back-btn {
    opacity: 0;
    transition-delay: 0.3s;
    color: rgb(51, 183, 246);
  }

  .search-wrapper:focus-within .search-icon {
    opacity: 0;
    transition-delay: 0s;
  }

  .search-wrapper:focus-within .search__back-btn {
    transform: rotate(360deg);
    opacity: 1;
    transition-delay: 0s;
  }

  .chat__contact-desc-input {
    background: white;
    padding: 8px;
    border-radius: 8px;
    font-size: 17px;
  }

  .chat__quickReply__card {
    cursor: pointer;
    :hover {
      background: white;
    }
  }

  .chat__quickReply__component {
    padding: 5px 15px;
    white-space: pre-wrap;
  }

  .chat__spinLoader {
    svg {
      fill: grey !important;
    }
    img {
      width: 100%;
    }
  }

  .chat__tagHamburger {
    border: 1px solid #b0b0b0;
    padding: 1%;
    margin-left: 2%;
    border-radius: 5px;
  }

  .chat__wabaNotProcured__container {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .chat__hrGreyLineCss {
    border: 2px solid lightgray;
  }

  .hrGreyLineCss {
    border: 0.5px solid #e4e4e7;
  }

  .chat__wabaNotProcured__ButtonCss {
    padding: 10px;
    background: #59bb31 !important;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 16px;
  }

  .chat__wabaNotProcured__TextCss {
    font-weight: 400;
    font-size: 18px;
    margin: auto;
  }

  .chat__noTemplate__container {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .chat__noTemplate__TitleTextCss {
    font-weight: 400;
    font-size: 18px;
    margin: auto;
  }

  .chat__noTemplate__TextCss {
    font-weight: 400;
    font-size: 20px;
    margin: auto;
    color: #afb4b6;
    padding-bottom: 30px;
  }

  .chat__noTemplate__ButtonCss {
    padding: 10px;
    background: #59bb31 !important;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 16px;
    width: 65%;
    margin: auto;
  }

  .chat__noTemplate__Image {
    margin: auto;
    padding-top: 70px;
    padding-bottom: 20px;
  }

  .chat__NoChatsFound {
    margin: auto;
    font-size: 22px;
    font-weight: 600;
    color: #afb4b6;
  }

  .chat__userProfileCloseButton {
    width: 90% !important;
    cursor: pointer;
    position: relative;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .chat__quickResponseContainer {
    line-break: anywhere;
    min-height: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100px;
  }

  .chat__tagButtonComponent {
    display: flex;
    justify-content: center;

    .Polaris-Button {
      padding: 10px;
      border-radius: 15px !important;
      margin: 20px;
      color: #59bb31;
      border: 1px solid;
      cursor: pointer;
    }
  }

  .chat__userProfile_tagButtonComponent {
    display: flex;
    justify-content: center;

    .Polaris-Button {
      padding: 10px;
      border-radius: 15px !important;
      margin: 10px;
      color: #59bb31;
      border: 1px solid;
      cursor: pointer;
    }
  }

  .chat__mediaBlock {
    display: block;
  }

  .chat__TagCheckbox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
    overflow: auto;
    height: 210px;
    line-break: anywhere;
  }

  .chat__TagCheckbox {
    .Polaris-Checkbox__Backdrop {
      border: 1px solid #5ec127;
      .Polaris-Checkbox--hover {
        border-color: #5ec127;
      }
    }
    .Polaris-Checkbox__Backdrop:hover {
      border-color: #5ec127;
    }
    .Polaris-Checkbox__Backdrop::before {
      background-color: #5ec127;
    }
    .Polaris-Checkbox__Backdrop::after {
      box-shadow: 0 0 0 calc(-1 * (var(--p-control-border-width) + 0.1rem))
        #5ec127;
    }

    .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop {
      background-color: #5ec127;
    }
  }

  .chat__input-wrapper {
    textarea {
      border-color: none;
    }
    textarea::placeholder {
      font-size: 1.5rem;
    }
  }

  .chat__arrow-wrapper {
    position: relative;
    top: 5px;
    left: 5px;
    cursor: pointer;
  }

  .chat__chatWindow__Backdrop {
    background-color: black;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  .chat__userProfile_mobile {
    z-index: 200;
    height: 100%;
    background: white;
    opacity: 1;
    width: 100%;
    position: absolute;
    margin-left: 40%;
    padding-right: 41%;
  }

  .chat__contact-desc-subTitle {
    font-size: 1.2rem;
  }

  .chat__timerContainer {
    right: 10px;
    position: relative;
    top: 2px;
  }

  .chat__arrow {
    width: 20px;
    height: 20px;
  }

  .chat__greenHamburger-wrapper {
    cursor: pointer;
    margin-left: 5px;
    position: relative;
    top: 6px;
  }

  .chat__greenHamburger {
    width: 40px;
    height: 40px;
  }

  .chat__sendTemplatesNotes {
    font-size: 1.5rem;
  }

  .chat__userProfile_header {
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    align-items: center;
    align-self: center;
  }

  .chat__userProfile_userName {
    font-weight: 600;
    font-size: 1.8rem;
  }

  .chat__userProfile_userPhone {
    font-weight: 400;
    font-size: 1.8rem;
    margin-top: -15px;
  }

  .chat__userProfile_noTags {
    font-style: italic;
    color: #6d7175;
    padding: 5px 10px 0px 0px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .chat__userProfile_tagMainContainer {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .chat__userProfile_tagContainer {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    right: 60px;
    white-space: nowrap;
    flex-flow: wrap;
    margin-bottom: 20px;
  }

  .chat__userProfile_tagComponent {
    border-radius: 5px;
    background: #f3f4f6;
    font-size: 1.2rem;
  }

  .chat__wabaNotProcured_mobile {
    width: 100%;
  }

  .chat__selectedTemplate__container {
    font-size: 1.5rem;
    line-height: 23px;
  }

  .chat__mediaPreview_importChat_container {
    background-color: #f3f4f6;
    width: 50%;
    border-radius: 10px;
    display: flex;
    gap: 30px;
    line-break: anywhere;
  }

  .chat__mediaPreview_importChat_name {
    gap: 3px;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    padding: 10px;
    width: 70%;
    font-size: 1.6rem;
  }

  .chat__mediaPreview_importChat_xcircle {
    margin: auto;
    cursor: pointer;
    min-height: 15px;
    min-width: 15px;
  }

  .chat__mediaPreview_container {
    background-color: #f3f4f6;
    min-height: 70px;
    border-radius: 10px;
    display: flex;
    gap: 30px;
    line-break: anywhere;
  }

  .chat__mediaPreview_name {
    gap: 3px;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    padding: 10px;
    width: 70%;
  }

  .chat__mediaPreview_xcircle {
    margin: auto;
    cursor: pointer;
    min-height: 15px;
    min-width: 15px;
  }

  .chat__userProfile_footer {
    position: relative;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .chat__userProfile_content {
    width: 95%;
    padding-left: 10px;
    margin-top: 2%;
  }

  .chat__userprofile_tagsHeader {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .chat__showAllTags {
    flex-flow: wrap;
  }

  .heightAuto {
    height: auto;
  }

  .marginAuto {
    margin: auto;
  }

  .buttonColor--primary {
    color: #73d13f;
  }

  .chat__cursorPointer {
    cursor: pointer;
  }
  .chat__associateButton {
    min-width: 50% !important;
    width: 80%;
    margin: 8% 5% 5% 10%;
  }

  .storeContainer--ios {
    height: calc(100vh - 16rem);
  }

  .storeUserDetailsContainer--ios {
    height: calc(100vh - 16rem);
  }

  .storeContainer--ios--safari {
    height: calc(100vh - 13.8rem);
  }

  .storeUserDetailsContainer--ios--safari {
    height: calc(100vh - 13.8rem);
  }

  .storeContainer--android {
    height: calc(100vh - 11rem);
  }

  .storeUserDetailsContainer--android {
    height: calc(100vh - 11rem);
  }

  .storeContainer--tablet {
    top: 0;
    height: calc(100vh - 13rem);
  }

  .storeUserDetailsContainer--tablet {
    height: calc(100vh - 13rem);
  }

  .chat__showImageSkeleton {
    cursor: pointer;
    width: 250px;
    height: 350px;
    border-radius: 5px;
    background: #e9e9e9;
    margin-bottom: 10px;
  }

  .chat__chatWindow__skeletonText {
    position: absolute;
    top: 45%;
    left: 35%;
    color: #b3b2b2;
    font-weight: 600;
    font-size: 1.4rem;
  }

  @media (max-height: 800px) {
    .chat__selectTemplateHeight {
      height: 40vh !important;
    }
    .chat__userProfile_userName {
      font-size: 1.1rem;
    }
    .chat__userProfile_userPhone {
      font-size: 1.1rem;
      margin-top: -20px;
    }
    .initials-avatar-container {
      .xlarge {
        width: 40px;
        height: 40px;
        font-size: 2.5rem;
      }
    }
    .chat__userProfile_header {
      gap: 15px;
      grid-gap: 15px;
    }
    .chat__TagCheckbox {
      height: 120px;
    }
    .tagElementCss {
      background: #f3f4f6;
      border-radius: 11px;
      padding: 1% 3%;
      color: gray;
      font-size: 0.9rem;
    }
    .chat__userprofile_tagsHeader {
      font-size: 1.4rem;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  @media (max-height: 700px) {
    .chat__TagCheckbox {
      height: 70px;
    }
  }

  @media (max-height: 600px) {
    .chat__TagCheckbox {
      height: 30px;
    }
    .chat__selectTemplateHeight {
      height: 35vh !important;
    }
  }

  @media (min-width: 1025px) and (max-width: 1100px) {
    .chat__timerContainer {
      right: 0px;
    }
  }
  @media (max-width: 750px) {
    .storeContainer {
      position: fixed !important;
      top: 58px;
    }
  }

  @media (max-width: 550px) {
    .chat__contact-name {
      font-size: 1.4rem;
    }
    .chat__contact-desc-subTitle {
      font-size: 1.2rem;
    }
    .chat__chatWindow-header {
      height: 70px;
      min-height: 70px;
      padding: 0px 0px 0px 10px;
      grid-gap: 0px;
      gap: 1%;
    }
    .chat__contact-wrapper {
      right: 8px;
      top: -3px;
    }
    .chat__arrow-wrapper {
      top: 0px;
      left: 0px;
    }
    .chat__timerContainer {
      right: 0px;
      position: relative;
      top: -4px;
    }
    .storeDetailsContainer .storeDetails .storeDetailsLeft .storeName {
      font-size: 2rem;
    }
    .chat__arrow {
      width: 15px;
      height: 15px;
    }
    .chat__greenHamburger {
      width: 35px;
      height: 35px;
    }
    .chat__greenHamburger-wrapper {
      top: 0px;
    }
    .chat__sendTemplatesNotes {
      font-size: 1.3rem;
    }
    .chat__sendTemplateButton {
      padding: 8px;
    }
    .chat__input-wrapper-template {
      padding: 10px;
      height: 120px;
    }
    .chat__input-wrapper {
      padding: 10px;
      height: 40px;
    }
    .chat__footer {
      padding: 12px 0 10px 0;
    }
    .chat__userProfile_userName {
      font-size: 1.5rem;
    }

    .chat__userProfile_userPhone {
      font-size: 1.5rem;
    }
    .storeDetailsContainer {
      min-width: 300px;
    }
    .chat__msg {
      max-width: 91%;
    }
    .chat__wabaNotProcured_mobile {
      scale: 0.8;
    }
  }

  @media (max-width: 420px) {
    .chat__arrow {
      width: 10px;
      height: 10px;
    }
    .chat__arrow-wrapper {
      top: -5px;
      left: -3px;
    }
    .chat__greenHamburger {
      width: 30px;
      height: 30px;
    }
    .chat__userProfile_userName {
      font-size: 1.1rem;
    }

    .chat__userProfile_userPhone {
      font-size: 1.1rem;
      margin-top: -20px;
    }
  }

  @media (max-width: 400px) {
    .chat__arrow {
      width: 10px;
      height: 10px;
    }
    .chat__arrow-wrapper {
      top: -5px;
      left: -3px;
    }
    .chat__greenHamburger {
      width: 25px;
      height: 25px;
    }
    .remainingTime {
      font-size: 0.9rem;
    }
    .storeDetailsContainer {
      .userListLeft {
        width: 60%;
      }
    }
    .chat__wabaNotProcured_mobile {
      scale: 0.7;
    }
  }

  /*
Select template CSS Start
*/

  .template {
    padding: 10px;
  }

  .chat__selectTemplateButton {
    max-width: 20%;
    border: 1px solid #73d13f;
    padding: 12px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    color: #73d13f;
    margin-bottom: 20px;
  }

  .chat__selectedTemplateButton {
    max-width: 20%;
    border: 1px solid #73d13f;
    padding: 12px;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    background-color: #73d13f;
    color: white;
  }

  .chat__template_input {
    padding: 8px;
    margin: 10px;
    border: 1px solid black;
    font-size: 16px;
  }

  .chat_selectedTemplate {
    padding: 25px;
    color: #3f3f3f;
    font-size: 2rem;
    font-weight: 600;
  }

  .chat_searchBar {
    padding-left: 20px;
    color: #3f3f3f;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .chat_radioButton {
    padding: 20px;
    color: #3f3f3f;
    font-size: 2rem;
    font-weight: 600;
    margin: 10px;
  }

  .chat__radioButtonBorderFilter {
    .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
      // border-color: #5ec127;
      border-color: var(--p-border);
    }
    .Polaris-RadioButton__Backdrop::before {
      background: #5ec127;
    }
    .Polaris-Choice__Label {
      font-size: 1.6rem;
    }
    .Polaris-Choice:hover {
      // .Polaris-RadioButton__Backdrop:hover {
      //   border-color: #5ec127;
      // }
      .Polaris-RadioButton__Backdrop {
        border-color: #5ec127;
      }
    }
  }
  .chat_templateNotFound {
    padding: 30px;
    font-size: 2rem;
    font-weight: 500;
    margin: auto;
    text-align: center;
  }

  .chat_headerTab {
    position: sticky;
    top: 0px;
    height: 7rem;
    background-color: white;
  }

  .chat_templatesSection {
    overflow: auto;
    height: 60vh;
    width: 98%;
    margin-left: 2%;
    margin-bottom: 1%;
  }

  .chat__templateHrLineCss {
    border: 1px solid #3f3f3f;
    opacity: 10%;
  }

  .chat__selectTemplateHeight {
    height: 60vh;
  }

  .chat__heightauto {
    height: auto;
  }

  @media (max-width: 820px) {
    .chat__selectTemplateButton {
      max-width: 48%;
      margin: auto;
      margin-top: 15px;
      margin-bottom: 20px;
    }
    .chat__selectedTemplateButton {
      max-width: 45%;
    }
  }

  .chat__linktoTemplate {
    width: 100%;
    text-align: right;
    margin-top: 20px;
    font-size: 1.4rem;
  }

  .chat__link {
    color: #5ec127;
  }

  /*
Select template CSS End
*/

  .playButton {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #cfcece;
    display: grid;
    place-content: center;
    position: absolute;
    left: 45%;
    top: 40%;

    span {
      color: #eee;
      font-size: 50px;
    }
  }
}
