.topRow {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}

.switch {
  display: flex;
  align-items: center;
  justify-items: center;
}

.bottomActions {
  display: flex;
  align-items: center;
  justify-items: center;
}

.middle {
  width: 60%;
}

.middle-50 {
  width: 50%;
}

.switchValue {
  padding-left: 5px;
}

.cardCover {
  padding: 20px;
}

.secondaryAction {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.secondaryLinkText {
  padding-left: 5px;
  color: #0087ee;
}

.secondaryExtenalIcon {
  --p-interactive: #0087ee;
}

.primaryAction {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 20px;
}

.primaryLinkText {
  padding-left: 5px;
  color: #5ec127;
}

.primaryExtenalIcon {
  --p-interactive: #5ec127;
}

.greenMonochromeButton {
  color: #5ec127;
}

.stats {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.cardCover {
  button {
    border-radius: 0.5rem !important;
    .Polaris-Icon {
      height: 1.5rem;
      svg {
        fill: #fff !important;
      }
    }
  }
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightTopBar {
  cursor: pointer;
  color: #5ec127;
}

.clickable {
  cursor: pointer;
}
