.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.template {
  .Polaris-Button {
    min-width: 12rem;
  }

  .Polaris-Select {
    border-radius: 2rem !important;
  }
}

.template-info-container {
  display: flex;
  align-items: center;
  .template-info {
    justify-content: center;
    min-width: 50%;
    width: 50%;
    overflow-wrap: break-word;
    margin-right: 1rem;
    .Polaris-TextStyle--variationSubdued {
      font-size: 1.2rem;
      margin: 0;
    }
  }
}

@media all and (max-width: 500px) {
  .template-info-container {
    margin-left: 4rem;
    flex-direction: column;
    align-items: flex-start;
    .template-info {
      min-width: 100%;
      width: 100%;
      overflow-wrap: break-word;
    }
  }
}
@media all and (max-width: 1070px) {
  .flex-col {
    margin-top: 1rem;
    flex-direction: row;
    justify-content: flex-end;
  }
  .delete-button-container {
    margin-left: 0.7rem;
  }
}

@media all and (max-width: 500px) {
  .templateSwitchMobCss {
    display: flex;
    justify-content: space-between;
  }
}

.crmTile {
  .react-switch-handle {
    width: 28px !important;
    height: 28px !important;
    top: -4px !important;
  }
  .react-switch-bg {
    left: 6px;
  }
}

.react-switch-handle {
  width: 28px !important;
  height: 28px !important;
}
.child {
  max-width: 300px;
}

.extensionButtonCss {
  position: relative;
}

@media all and (min-width: 1069px) and (max-width: 1122px) {
  .extensionButtonCss {
    margin-top: 1rem;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.CRM__bold {
  font-weight: 900;
}

.CRM__link {
  color: blue;
}

.deleteLogoCampaign {
  position: relative;
  bottom: 3px;
  cursor: pointer;
}
