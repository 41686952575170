.basic-contactUs-modal {
  display: flex;
  flex-flow: column;
  padding: 25px;
}

textarea {
  resize: vertical;
}

.ButtonFooter {
  justify-content: center !important;
  display: flex;
  padding: 10px;

  .Polaris-Button {
    border-radius: 5px !important;
  }

  .cancelButton {
    color: #5dc001;
    border-color: #5dc001;
  }

  .saveButton {
    padding: 5px 25px 5px 25px;
  }
}

.contactUsRadioButton {
  line-height: 10px;
}

.Polaris-TextField--multiline > .Polaris-TextField__Input {
  max-height: 100px !important;
}
