.wltByline {
  font-weight: bold;
}

.wltAvlBal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.wltAvlBalCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.wltAvlBalComp {
  min-width: 45%;
}

.wltAvlBalNum {
  font-size: 2.5rem;
  font-weight: 600;
}

.wltBillingSec {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.wltBillingCard {
  display: flex;
  flex-direction: column;
  width: 47.5%;
  border: 1px solid #efefef;
  padding-top: 25px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 25px;
  margin-top: 20px;
  height: 170px;
  border-radius: 8px;
}

.wltBillingBtn {
  font-family: "Rubik", sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #5ec127;
  border: 1px solid #5ec127;
  background: #ffffff;
  border-radius: 5px;
  width: 60%;
  margin-top: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
  cursor: pointer;
}

.wltHeader {
  font-size: 28px;
  font-weight: 500;
}

.wltBillingCardTxt {
  margin-top: 5px;
  height: 80px;
}

.wltUsageTopBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.wltUsageBtn {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.wltUsageBtnText {
  font-family: "Rubik", sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #5ec127;
  border: 1px solid #5ec127;
  background: #ffffff;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.wltUsageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0px 20px;
}

.wltUsageCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.wltUsageCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.wltUsageNumber {
  font-size: 24px;
}

.wltUsageIcon {
  align-self: flex-start;
}

.wltUsageDownloadBtn {
  padding-right: 10px;
}

.wltUsageBtnText {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.wltBillingHeader {
  font-size: 28px;
  font-weight: 500;
  margin-top: 40px;
}

.wltBalRightRowContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.wltBalRightRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.wltBalanceContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
}

.wltBalanceRight {
  width: 30%;
}

.wltBalanceLeft {
  width: 65%;
}

.wltAddBalBtn {
  width: 55%;
  text-align: right;
}

.wltBalRightAmountText,
.wltBalRightAmountNumber {
  font-size: 14px;
  font-weight: 400;
}

.wltBalRightTotalText,
.wltBalRightTotalNumber {
  font-size: 16px;
  font-weight: 500;
}

.wltBalRightButton {
  margin-top: 15px;
}

@media all and (max-width: 768px) {
  .wltUsageGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (max-width: 600px) {
  .wltBalanceContainer {
    flex-direction: column;
    margin: auto;
    grid-gap: 0;
  }
  .wltBalanceRight {
    width: 80%;
    margin-left: 3%;
  }

  .wltBalanceLeft {
    width: 80%;
    margin-left: 3%;
  }
  .wltBalRightButton {
    margin: auto;
    width: 80%;
  }
}
@media all and (max-width: 500px) {
  .wltUsageGrid {
    grid-template-columns: 1fr;
    width: 90%;
  }
  .wltAvlBalCard {
    width: 90%;
  }
  .wltUsageBtn {
    justify-content: center;
  }
  .wltBillingBtn {
    width: 100%;
  }
  .wltBillingCard {
    padding: 5% 2% 0 2%;
  }
}

.planRecharge__card {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.planRecharge__left {
  min-width: 40%;
}

.planRecharge__right {
  align-self: flex-start;
  .statusCss {
    border: 1px solid #202223;
    border-radius: 10px;
    display: flex;
    padding: 0px 10px;
  }
}

.badgeLabel {
  color: #202223;
  line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 11px;
}

.currentPlanPrice {
  line-height: 3rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.badgeActive {
  font-size: 1rem;
  font-weight: 500;
  padding: 0px 5px;
}

.badgeInactive {
  font-size: 1rem;
  font-weight: 500;
  padding: 0px 5px;
}

.details {
  align-self: flex-end;
  font-size: 1rem;
  color: rgb(94, 193, 39);
  cursor: pointer;
}
