.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffffed;
  z-index: 500;
}

.loader-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.loading-text {
  color: #4ada06;
  font-weight: 600;
  font-size: 15px;
  padding: 2rem;
}

.loading-note {
  color: #4ada06;
  font-weight: 600;
  font-size: 15px;
  padding: 2rem;
}

.w3-spin {
  animation: w3-spin 5s infinite linear;
}

@keyframes w3-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
