.sl-mobile-main-container {
  background-color: #fafbfb;
  //border: 1px solid #ebebeb;
  margin: auto;
}

.sl-mobile-container {
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 30px;
}
.preview {
  padding: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.sl-mobile {
  margin: auto;
  border-radius: 17px;
  border: solid black;
  border-width: 5rem 10px 10rem 10px;
  height: 500px;
  width: 250px;
}
.marginVAuto {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3px;
}
.white {
  color: white;
}
.whatsappHeader {
  display: flex;
  height: 40px;
  background-color: #245d54;
  .Polaris-Icon--colorBase svg {
    fill: white;
  }
  img {
    height: 30px;
  }
}
.whatsapp-message-container {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
  height: 100%;
  background: url('../images/whatsapp_chat_doodle2.png') repeat;
  background-origin: content-box;
  background-size: contain;
  overflow-y: auto;
}

.whatsapp-preview-header {
  /* padding: 1em 0 0.5em 0; */
  margin-left: 1em !important;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
}

.whatsapp-message-inner-container {
  max-width: 250px;
  min-width: 30%;
  /* height: 100%; */
  display: inline-block;
  clear: both;
  /* border: 1px solid black; */
  font-size: 15px;
  margin: 2% -8px;
  width: 100%;
}

.whatsapp-message.received {
  background: #fff;
  /* background: black; */
  /* border-radius: 0px 5px 5px 5px; */
  border-radius: 0px 7.5px px 7.5px 7.5px;
  /* float: left; */
  width: 250px;
}

.whatsapp-message .text-header {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
  color: rgba(0, 0, 0, 0.76);
  font-size: 15px;
  font-weight: bold;
}
.call_to_action_button {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
  /* border: 1px solid black; */
  color: #00a5f4;
  font-size: 13.5px;
  height: 35px;
  line-height: 30px;
  padding: 2px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  background: white;
  margin-left: 8px;
}

.quick_reply_button {
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15) !important;
  box-sizing: border-box;
  flex-grow: 1;
  min-width: calc(50% - 2px);
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  padding: 0 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #00a5f4;
  text-align: center;
  margin-top: 2px;
  font-family: sans-serif !important;
}
html[dir='ltr'] .message-in ._3nrYb,
html[dir='ltr'] .tail-override-left ._3nrYb {
  left: -8px;
}

.message-in ._3nrYb {
  color: var(--incoming-background);
}
._3nrYb {
  position: absolute;
  top: 0;
  z-index: 100;
  display: block;
  width: 8px;
  height: 13px;
}

.time {
  float: right;
  font-size: 10px;
  padding-right: 10px;
  color: dimgrey;
}
.whatsapp-message.received .pre p,
.whatsapp-message.received .footer,
.whatsapp-message.received .header {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
  white-space: pre-wrap;
  direction: ltr;
  unicode-bidi: normal;
  text-align: left;
  letter-spacing: 0.2px;
  font-weight: normal;
  font-size: 12px;
  margin-left: 5px;
}

.whatsapp-message.received .header {
  font-weight: bold;
}
.whatsapp-message.received .footer {
  color: rgba(0, 0, 0, 0.45);
}
.whatsapp-message.received .text-header {
  margin-top: 10px;
}

.quick_reply_buttons a {
  text-decoration: none;
  font-size: 15px;
  color: #00a5f4;
  font-weight: bold;
}
.quick_reply_buttons a:hover {
  text-decoration: none;
  font-size: 15px;
  color: #00a5f4;
  font-weight: bold;
}

.quick_reply_buttons .Polaris-Icon {
  display: block;
  height: 2rem;
  margin-left: 0px;
  margin-right: 0px;
}
.quick_reply_buttons .Polaris-Icon--colorBase svg {
  fill: #00a5f4;
}
.quick_reply_button .Polaris-Icon__Svg {
  width: 17px;
}
.quick_reply_button {
  cursor: pointer;
}
