.run-campaign-button {
  color: #5ec127;
  padding-left: 10%;
  .Polaris-Button--plain {
    svg {
      fill: #f37f77 !important;
    }
  }
  .Polaris-Button--outline {
    path {
      stroke: #5ec127 !important;
    }
  }
}

.button-design-new {
  button {
    border-radius: 0.5rem !important;
    .Polaris-Icon {
      height: 1.5rem;
      svg {
        fill: #fff !important;
      }
    }
  }
}

.radioButtonBorderFilter {
  .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
    border-color: #5ec127;
  }
  .Polaris-RadioButton__Backdrop::before {
    background: #5ec127;
  }
}

.learnMoreCreateCompaignCss {
  position: relative;
  bottom: 5px;
}

.campaignDownloadReportCss {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
