.run-campaign-button {
  color: #5ec127;
  padding-left: 10%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  .Polaris-Button--plain {
    svg {
      fill: #f37f77 !important;
    }
  }
  .Polaris-Button--outline {
    path {
      stroke: #5ec127 !important;
    }
  }
}

.button-design-new {
  button {
    border-radius: 0.5rem !important;
    .Polaris-Icon {
      height: 1.5rem;
      svg {
        fill: #fff !important;
      }
    }
  }
}

.radioButtonBorderFilter {
  .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
    border-color: #5ec127;
  }
  .Polaris-RadioButton__Backdrop::before {
    background: #5ec127;
  }
}

.learnMoreCreateCompaignCss {
  position: relative;
  bottom: 5px;
}

.campaignDownloadReportCss {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.btn {
  background-color: #5ec127;
  color: white;
  // font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  // border-radius: 12px 0 0 12px;
}

.btn-right {
  margin-left: 1rem;
  border-radius: 2rem 0 0 2rem;
  padding: 1rem 1.6rem;
}

.btn-submit {
  margin-left: 1rem;
  border-radius: 2rem;
  padding: 1rem 1.6rem;
  // border-radius: 12px 0 0 12px;
}

.btn-left {
  border-radius: 0px 2rem 2rem 0px;
  border-left: 1px solid white;
  padding: 1rem 1rem 1rem;
}

// @media (min-width: 633px) and (max-width: 768px) {
//   .column {
//     width: 50%;
//   }
// }

// @media (min-width: 769px) and (max-width: 900px) {
//   .column {
//     width: 100%;
//   }
// }

// @media (min-width: 901px) and (max-width: 1400px) {
//   .column {
//     width: 50%;
//   }
// }

.dropdown {
  position: absolute;
  display: inline-flex;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  z-index: 1;
  border-radius: 1.5rem;
  bottom: 29%;
  left: 40.5%;
  color: #5ec127;
}

.schedule_p {
  color: black;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.schedule_p:hover {
  color: #5ec127;
}

.schedule_button {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3rem;
  margin-right: 6rem;
}

.campaign__scheduler {
  height: 250px;
}
