.wa-chat-btn-fixed {
  position: fixed;
}

.wa-chat-btn-default {
  font-size: 14px;
  text-decoration: none;
  z-index: 999999999999999 !important;
  position: fixed;
  border-radius: 5px;
  bottom: 20px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  background: #ffffff;
  color: green;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px #d3d3d3;
}

.wa-chat-btn-default-waicon {
  width: 25px;
  vertical-align: middle;
  margin-right: 4px;
}

.wa-chat-btn-base-cta {
  text-decoration: none;
  z-index: 999999999999999 !important;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0px 0px 5px 0px #d3d3d3;
  display: table;
}

.wa-chat-btn-base-cta-rounded {
  text-decoration: none;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0px 0px 5px 0px #d3d3d3;
  display: table;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.wa-chat-btn-base-icon {
  text-decoration: none;
  z-index: 999999999999999 !important;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0px 0px 8px 0px rgba(194, 194, 194, 1);
}

.wa-chat-btn-base-icon.no-box-shadow {
  background: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.wa-chat-btn-left {
  left: 20px;
}

.wa-chat-btn-right {
  right: 20px;
}

.wa-chat-btn-container-size-big {
  font-size: 14px;
  padding: 10px 10px 10px 10px;
}

.wa-chat-btn-container-size-rounded-big {
  font-size: 14px;
  padding: 10px 16px;
}

.wa-chat-btn-container-size-small {
  font-size: 12px;
  padding: 6px 6px 6px 6px;
}

.wa-chat-btn-container-size-rounded-small {
  font-size: 12px;
  padding: 6px 10px;
}

.wa-chat-btn-icon-cta-big {
  width: 25px;
  vertical-align: middle;
  margin-right: 4px;
}

.wa-chat-btn-icon-cta-small {
  width: 20px;
  vertical-align: middle;
  margin-right: 4px;
}

.wa-chat-btn-icon-image-only {
  width: 52px;
  height: 52px;
  vertical-align: middle;
}

.wa-chat-btn-theme-cta-old {
  background: #f9f9f9;
  color: green;
}

.wa-chat-btn-theme-cta-new {
  background: white;
  color: green;
}

.wa-chat-btn-theme-cta-new-inverted {
  background: #35cb5e;
  color: white;
}

.wa-chat-btn-theme-cta-black {
  background: white;
  color: black;
}

.wa-chat-btn-theme-cta-black-inverted {
  background: black;
  color: white;
}

.wa-chat-button-cta-text {
  display: inline-block;
  vertical-align: middle;
}

.rounded .wa-chat-button-cta-text {
  border-radius: 100px !important;
  -webkit-border-radius: 100px !important;
  -moz-border-radius: 100px !important;
  -ms-border-radius: 100px !important;
  -o-border-radius: 100px !important;
}

.wa-chat-btn-base-cta-with-icon.b-grey .wa-chat-button-cta-text {
  background-color: #e9eff5;
  padding: 4px 9px;
  margin: 0px 8px;
  border-radius: 4px;
  color: #707070;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0px 0px 5px 0px #d3d3d3;
}

/* CUSTOMIZATION OF CHAT TEMPLATE BTN - AUTHOR - PRASHANT */
.wa-chat-btn-base-cta-with-icon img {
  border-radius: 100%;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0px 0px 5px 0px #d3d3d3;
  cursor: pointer;
}

.wa-chat-btn-base-cta-with-icon img.no-radius {
  border-radius: 0rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.wa-chat-btn-base-cta-with-icon .wa-chat-button-cta-text {
  background-color: white;
  padding: 4px 9px;
  margin: 0px 8px;
  width: 90px;
  border-radius: 4px;
  color: #2c2c2c;
  font-size: 1.16rem;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0px 0px 5px 0px #d3d3d3;
}

.previewBtnBackground .wa-chat-btn-default,
.wa-chat-btn-base-cta,
.wa-chat-btn-base-cta,
.wa-chat-btn-base-icon {
  z-index: unset !important;
  position: static;
}

.wa-custom-chat-btn.wa-chat-btn-base-icon,
.wa-custom-chat-btn .wa-chat-btn-base-icon {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wa-custom-chat-btn .wa-chat-btn-icon-image-only {
  width: 34px;
  height: 34px;
}

.wa-custom-chat-btn.wa-chat-btn-base-cta-with-icon {
  display: flex;
  align-items: center;
}

.wa-custom-chat-btn.wa-chat-btn-base-cta-with-icon .wa-chat-btn-base-icon {
  display: inline-flex;
}

.wa-custom-chat-btn.wa-chat-btn-base-cta-with-icon img {
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-chat-btn-templates .wa-custom-chat-btn {
  margin: 1.6rem;
}

.wa-custom-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
}

.wa-custom-icon.adjust-icon {
  margin-top: 0.5rem;
}

.w-30 {
  width: 30px !important;
}
.w-3 {
  width: 52px !important;
  height: 52px !important;
}
.w-6 {
  width: 6rem !important;
  height: 6rem !important;
}

@media screen and (max-width: 768px) {
  .wa-chat-btn-container-size-big {
    width: 127px;
  }

  .wa-chat-btn-container-size-small {
    width: 108px;
  }

  .custom-chat-btn-templates .wa-custom-chat-btn {
    margin: 1.6rem 0rem;
  }
}
