@import './assets/css/variables.scss';

@media (max-width: 600px) {
  .Polaris-Page {
    max-width: 95% !important;
  }
}

.Polaris-VisuallyHidden {
  display: none !important;
}
.Polaris-Frame__Main,
.Polaris-Navigation {
  background: white !important;
}
.Polaris-Page {
  .Polaris-Header-Title {
    font-size: 3rem;
  }
  .Polaris-Header-Title__SubTitle {
    margin-bottom: 0.8rem;
    color: #000;
  }
  .Polaris-Header-Title__SubTitle-light {
    margin-bottom: 0.8rem;
    color: rgb(100, 97, 97);
  }
}
.Polaris-DisplayText {
  line-height: 4rem;
}
.Polaris-Navigation__Item {
  font-weight: 400 !important;
}
.Polaris-Navigation__Item--selected {
  color: $primary-color !important;
}
.Polaris-Navigation__Item--selected::before {
  background-color: $primary-color !important;
}
.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg {
  fill: $primary-color !important;
}
.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg path {
  stroke: $primary-color !important;
}
.Polaris-Badge--statusSuccess {
  color: $primary-color !important;
  background: #edfae6 !important;
}

// .Polaris-Popover {
// max-width: fit-content !important;
// }
.Polaris-Button {
  border-radius: 2rem !important;
}
.Polaris-Button--primary {
  border-radius: 2rem !important;
  background-color: $primary-color !important;
  box-shadow: none !important;
}
.Polaris-Button--primary.outline,
.Polaris-Button--primary.Polaris-Button--outline {
  background: none !important;
  border-radius: 2rem !important;
  border-color: $primary-color !important;
  color: $primary-color !important;
  box-shadow: none !important;
}
.Polaris-Button--primary.Polaris-Button--loading {
  color: #02574280 !important;
}
.Polaris-Button--primary.outline:hover {
  border-radius: 2rem !important;
  border-color: $primary-color !important;
  background-color: $primary-color !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.Polaris-Banner--withinContentContainer {
  border-radius: 1rem !important;
}
.Polaris-Banner--statusSuccess.Polaris-Banner--withinContentContainer {
  background-color: #f1fae8 !important;
}
.release-heading h2 {
  color: #0e9b0e;
  font-size: 2rem;
  text-decoration: underline;
}
.text-center {
  text-align: center !important;
}
.m-1 {
  margin: 1rem 1rem !important;
}
.m-2 {
  margin: 2rem 2rem !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-1-half {
  margin-top: 0.5rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.pl-2 {
  padding-left: 2rem !important;
}
.pr-2 {
  padding-right: 2rem !important;
}
.pt-0 {
  padding-top: 0rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pt-2 {
  padding-top: 1.5rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-2 {
  padding-bottom: 1.5rem !important;
}
.fs-xxlarge {
  font-size: 4rem !important;
}
.fs-xlarge {
  font-size: 3rem !important;
}
.fs-large {
  font-size: large !important;
}
.fs-1 {
  font-size: 2.5rem !important;
}
.fs-2 {
  font-size: 2rem !important;
}
.fs-3 {
  font-size: 1.75rem !important;
}
.fs-4 {
  font-size: 1.5rem !important;
}
.fs-5 {
  font-size: 1.25rem !important;
}
.fs-6 {
  font-size: 1rem !important;
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.w-80 {
  width: 80% !important;
}
.w-70 {
  width: 70% !important;
}
.w-60 {
  width: 60% !important;
}
.w-50 {
  width: 50% !important;
}
.w-52 {
  width: 53% !important;
}
.w-40 {
  width: 40% !important;
}
.width-30 {
  width: 30% !important;
}
.w-20 {
  width: 20% !important;
}
.w-15 {
  width: 15% !important;
}
.w-10 {
  width: 10% !important;
}
.w-1 {
  width: 15rem !important;
}
.w-2 {
  width: 20rem !important;
}
.h-1 {
  height: 15rem !important;
}
.h-2 {
  height: 20rem !important;
}
.h-3 {
  height: 30rem !important;
}
.r-0 {
  right: 0;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.alignEnd {
  text-align: flex-end !important;
}
.break-word {
  word-break: break-word;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.d-flex-end {
  display: flex;
  justify-content: flex-end;
}
.alignSelfStart {
  align-self: flex-start;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.vertical-align-center {
  align-items: center;
}
.text-primary {
  color: #5dc001 !important;
}
.strong {
  font-weight: 700 !important;
}
.font-weight-normal {
  font-weight: normal;
}
.absolute {
  position: absolute !important;
}
.relative {
  position: relative !important;
}
.pointer {
  cursor: pointer;
}
.pointerNone {
  cursor: not-allowed !important;
}
.checkoutOptinModalImage {
  width: 98%;
}
.checkoutOptinModalImageMoreWidth {
  width: 98%;
}
.overflow-wrap {
  overflow-wrap: break-word;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
.Polaris-Modal-Dialog__Modal {
  max-width: 82rem !important;
}
.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
  max-width: 46rem !important;
}
.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
  max-width: 98rem !important;
}
.imgchatscale1 {
  width: 162px;
}
.imgchatscale2 {
  width: 138px;
}
.imgchatscale3 {
  width: 58px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-2-5rem {
  font-size: 2.5rem;
}

.font-size-1-8rem {
  font-size: 1.8rem;
}

.color-grey {
  color: #6d7175;
}

.gap-5 {
  gap: 5px;
}

.gap-3rem {
  gap: 3rem;
}

.ellipsis-hidden-nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Polaris-ActionMenu-SecondaryAction {
  .Polaris-Button--outline {
    background: none !important;
    border-radius: 2rem !important;
    border-color: $primary-color !important;
    color: $primary-color !important;
    box-shadow: none !important;
    border: 1px solid $primary-color !important;

    svg {
      fill: #5dc001;
    }
  }
  .Polaris-Button--outline:hover {
    border-radius: 2rem !important;
    border-color: $primary-color !important;
    background-color: $primary-color !important;
    color: #ffffff !important;
    box-shadow: none !important;

    svg {
      fill: #ffffff;
    }
  }
}
.Polaris-ActionMenu-SecondaryAction {
  position: relative !important;
  right: 4rem !important;
}
.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title::before {
  background: $primary-color !important;
}

.Polaris-Tabs__Tab--selected {
  --p-focused: none;
}

.Polaris-ActionMenu-RollupActions__RollupActivator {
  margin-right: 0.8rem !important;
}
.tooltip-overlay {
  position: absolute;
  right: 9%;
  top: 3rem;
  z-index: 100;
}
@media (max-width: 1600px) {
  .tooltip-overlay {
    right: 10%;
  }
}
@media (max-width: 1400px) {
  .tooltip-overlay {
    right: 6%;
  }
}
@media (max-width: 1300px) {
  .tooltip-overlay {
    right: 4%;
  }
}
@media (max-width: 770px) {
  .tooltip-overlay {
    right: 20%;
  }
}

@media (max-width: 420px) {
  .tooltip-overlay {
    right: 0.5%;
  }
}
.increase-top {
  top: 22rem;
}

@media (max-width: 1050px) {
  .increase-top {
    top: 24rem;
  }
}

@media (max-width: 875px) {
  .increase-top {
    top: 26rem;
  }
}

@media (max-width: 560px) {
  .increase-top {
    top: 31.8rem;
  }
}

@media (max-width: 405px) {
  .increase-top {
    top: 33rem;
  }
}

@media (max-width: 380px) {
  .increase-top {
    top: 35.8rem;
  }
}
@media (max-width: 340px) {
  .increase-top {
    top: 41rem;
  }
}

.tooltip-outer-container .icon .tooltip-svg-icon {
  width: 2.9rem;
  margin: 4px;
}
.tooltip-outer-container {
  display: flex;
  justify-content: flex-end;
}
.tooltip-hover-content {
  box-shadow: 2px 3px 14px -6px rgba(0, 0, 0, 0.91);
  -webkit-box-shadow: 2px 3px 14px -6px rgba(0, 0, 0, 0.91);
  -moz-box-shadow: 2px 3px 14px -6px rgba(0, 0, 0, 0.91);
  border-radius: 0.5rem;
  background-color: #fff;
}
.tooltip-hover-content .inner-content {
  display: none;
  padding: 2.8rem 2.4rem;
  max-width: 30rem;
}

.tooltip-outer-container:hover + .tooltip-hover-content .inner-content {
  display: block;
}
.tooltip-hover-content:hover .inner-content {
  display: block;
}

.Polaris-Tooltip-TooltipOverlay__Content {
  max-width: max-content !important;
  // background-color: #212b36 !important;
}
.calloutCardContentContainer .Polaris-CalloutCard__Buttons .Polaris-Button {
  border-radius: 2rem !important;
  background-color: $primary-color !important;
  box-shadow: none !important;
  color: #ffffff !important;
}
.calloutCardContentContainer .Polaris-CalloutCard__Image {
  width: 26% !important;
  margin: 0 16px;
  opacity: 0.8;
}
.calloutCardContentContainer .Polaris-CalloutCard__Title {
  margin-bottom: 1.4rem !important;
}
.calloutCardContentContainer .Polaris-TextContainer {
  color: #46494a !important;
}
.pre-line {
  white-space: pre-line;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.Polaris-TextField__Prefix,
.Polaris-TextField__Suffix {
  pointer-events: all !important;
}
.Polaris-Icon--colorPrimary .Polaris-Icon__Svg {
  fill: $primary-color !important;
}
.Polaris-Icon--colorWarning .Polaris-Icon__Svg {
  fill: red !important;
}
.Polaris-Icon--colorSubdued .Polaris-Icon__Svg {
  fill: gray !important;
}
.no-border {
  border: none !important;
}
.card-integration {
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 2rem;
}
.powered-by {
  .Polaris-FooterHelp__Content {
    border: 0.1rem solid #dfe3e8 !important;
    border-radius: 999px !important;
  }
  .Polaris-FooterHelp__Icon {
    opacity: 0 !important;
    margin-right: 0.8rem;
  }
}
.v-align {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.login-container {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  .login-left {
    width: 55%;
    display: flex;
    flex-direction: column;
    padding: 5rem;
    background: #f9fdf5;
    height: 100%;
  }
  .login-right {
    width: 45%;
    height: 100%;
    padding: 2%;
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .login-content {
    align-self: center;
    min-width: 65%;
  }
  .login-image {
    padding: 10rem;
    align-self: center;
    width: 75rem;
    padding-bottom: 0rem;
  }
  .login-right-image {
    align-self: center;
    width: 75rem;
    zoom: 0.6;
  }
  .signin-form {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .forgot-pass-link {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    color: #0087ee;
    text-decoration: underline;
    cursor: pointer;
  }
  .login-button {
    background: #5ec127;
    border: 1px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  .login-button-outline {
    height: 41px;
    width: 80%;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .loginBtnIcon {
    margin: 5%;
    display: flex;
  }
  .loginBtnText {
    margin-top: 5px;
    padding-bottom: 5px;
    margin-right: 5px;
  }
  .hline {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .hline:before,
  .hline:after {
    content: '';
    flex: 1 1;
    border: 1px solid #d2d2d2;
    margin: auto;
  }
  .social-login-container {
    // Full page with 4 buttons CSS
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-column-gap: 10px;
    // grid-row-gap: 10px;

    // Only 2 buttons CSS
    display: flex;
    //flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 50px;
    gap: 20px;
    padding-bottom: 25px;
  }

  .social-login-container-signup {
    // Full page with 4 buttons CSS
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-column-gap: 10px;
    // grid-row-gap: 10px;

    // Only 2 buttons CSS
    display: flex;
    //flex-direction: column;
    align-items: flex-start;
    //justify-content: center; //uncomment when all login methods are used
    gap: 20px;
    padding-bottom: 25px;
  }

  .w50p {
    width: 50%;
  }

  @media all and (min-width: 900px) and (max-width: 1250px) {
    .login-right {
      padding: 5%;
      padding-right: 5%;
    }
  }
  @media all and (min-width: 901px) {
    .login-right-image {
      margin-bottom: 20%;
      zoom: 0.6;
    }
    .login-content {
      margin-bottom: 8%;
      min-width: 50%;
    }
  }
  @media all and (max-width: 900px) {
    flex-direction: column;
    .login-content {
      margin-bottom: 0;
      padding: 0;
      zoom: 1.2;
    }
    .login-image {
      padding: 0;
      align-self: center;
      width: 80%;
    }
    .login-right-image {
      zoom: 0.75;
    }
    .login-image-div {
      position: relative;
      display: flex;
      justify-content: center;
    }
    .login-right {
      width: 70%;
      margin: auto;
      padding: 0;
    }
    .login-left {
      width: 70%;
    }
  }

  @media all and (max-width: 600px) {
    .login-right {
      width: 65%;
      margin: auto;
      padding: 0;
    }
    .login-right-image {
      zoom: 0.6;
    }
    .login-image {
      padding: 0;
      align-self: center;
      width: 60%;
    }
  }
  @media all and (max-width: 480px) {
    .login-right {
      width: 76%;
      margin: auto;
      padding: 0;
    }
    .login-image {
      width: 100%;
    }
    .login-right-image {
      zoom: 0.4;
    }
    .login-content {
      zoom: 1;
    }
  }
  @media all and (max-width: 400px) {
    .loginBtnText {
      font-size: 12px;
    }
  }
}

.login {
  margin-top: 2em;
  .Polaris-TextField {
    width: 60%;
    margin: 0 auto;
    .Polaris-TextField__Backdrop {
      border-radius: 2rem;
    }
  }

  .Polaris-InlineError {
    display: inline-flex;
  }

  @media all and (max-width: 900px) {
    margin-top: 5rem;
  }
}
.upgrade-text {
  width: 30rem !important;
}
.Polaris-Link-Updated-Color {
  color: #5dc001 !important;
}
.Polaris-Card {
  background-color: #fafbfd !important;
}
.celebrate-svg-icon {
  width: 5rem;
}
.bell-icon img {
  width: 2.7rem;
}

.notification-bell {
  width: 3.7rem !important;
}

.Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg {
  fill: rgba(0, 128, 96, 1) !important;
}

.Polaris-Layout {
  margin-left: 0rem !important;
}
.balanceStatus {
  background: #f2f2f2;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 50%;
  font-size: 10px;
  line-height: 10px;
  margin-right: 10px;
  .textBlack {
    color: #000000;
  }
  svg {
    fill: #cd9c12;
  }
  .balanceButtons {
    color: #0e9b0e;
    cursor: pointer;
  }
  .flexCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}
.flexCenterAlign {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 2rem;
}
.flexrightAlign {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15rem;
  @media (max-width: 1200px) {
    padding-right: 5rem;
  }
  @media (max-width: 500px) {
    padding-right: 3rem;
  }
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.overLayText {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.d-flex-flow-col {
  flex-flow: column;
}

.nudgeScreen-singlebox {
  display: flex;
  flex-flow: row;

  @media (min-width: 769px) and (max-width: 840px) {
    display: block;
  }

  @media (max-width: 621px) {
    display: block;
  }
}

.nudgeScreen-teaminbox {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 769px) and (max-width: 840px) {
    display: block;
  }

  @media (max-width: 621px) {
    display: block;
  }
}

.nudgeScreen-teaminbox-adjusted {
  display: flex;
  flex-flow: row;
  align-items: center;

  @media (min-width: 769px) and (max-width: 840px) {
    display: block;
  }

  @media (max-width: 621px) {
    display: block;
  }
}

.nudgeScreen-teaminbox-enterprise {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 11.68rem;

  @media (min-width: 769px) and (max-width: 840px) {
    display: block;
  }

  @media (max-width: 621px) {
    display: block;
  }
}

.nudgeScreen-leftColumn {
  max-width: 500px;
  margin-top: -3rem;
}

.nudgeScreen-leftColumn-padded {
  max-width: 500px;
  margin-top: 3rem;
  padding-right: 30px;
  align-self: flex-start;
}

.nudgeScreen-rightColumn {
  margin-top: -2.5rem;
  --p-focused: rgb(255 255 255);

  .Polaris-TextField__Input {
    border-color: #71c227;
    border-radius: 4px;
    border-width: 2px;
  }
}

.nudgeScreenExtensionMob {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  @media (min-width: 769px) and (max-width: 885px) {
    display: block;
  }

  @media (max-width: 649px) {
    display: block;
  }
}

.orCSSMob {
  position: relative;
  top: 200px;
  width: 0;
  @media (min-width: 769px) and (max-width: 885px) {
    top: 5px;
    left: 130px;
  }

  @media (max-width: 649px) {
    top: 5px;
    left: 130px;
  }
}

.wheelconfigScrollCss {
  @media all and (max-width: 650px) {
    font-size: 1rem;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media all and (min-width: 767px) and (max-width: 890px) {
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.wheelconfigScrollCss::-webkit-scrollbar {
  @media all and (max-width: 650px) {
    display: none;
  }

  @media all and (min-width: 767px) and (max-width: 890px) {
    display: none;
  }
}

.chatbot {
  .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
    border-color: #5dc001 !important;
  }

  .Polaris-RadioButton__Input + .Polaris-RadioButton__Backdrop {
    border-color: #5dc001 !important;
  }

  .Polaris-RadioButton__Backdrop::before {
    background-color: #5dc001 !important;
  }
}

.quickOverviewMarkup {
  .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
    border-color: #5dc001 !important;
  }

  .Polaris-RadioButton__Input + .Polaris-RadioButton__Backdrop {
    border-color: #5dc001 !important;
  }

  .Polaris-RadioButton__Backdrop::before {
    background-color: #5dc001 !important;
  }
}
.customSelect {
  * {
    position: inherit;
    z-index: 25;
  }

  .css-b62m3t-container {
    margin-top: 3px;
  }
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  margin-top: -3.2%;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
}

.arrow-down {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black;
}

.DropDownArrowTopBar {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 28px;
  border-radius: 0px 3px 3px 0px;
  height: 44px;
  margin-left: -8px;
  padding: 0.6rem 0.8rem;
}

.DropDownArrowTopBar-pressed {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 28px;
  border-radius: 0px 3px 3px 0px;
  height: 44px;
  margin-left: -8px;
  padding: 0.6rem 0.8rem;
  background-color: var(--p-surface-pressed);
}

.topBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 3px;
}

.topBarContainer {
  --p-focused: none;
}

.topBarContainer:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-TopBar-Menu__Activator {
  border-radius: 3px 0px 0px 3px !important;
}

@media all and (max-width: 769px) {
  .DropDownArrowTopBar {
    display: none;
  }
  .Polaris-TopBar__Contents {
    position: relative;
    right: 0px !important;
  }
}

.Polaris-TopBar__NavigationIcon {
  z-index: 1000;
}

.Polaris-TopBar__Contents {
  position: relative;
  right: 30px;
}

.spinWheelConfig {
  padding-right: 40px;
}

.gap1 {
  gap: 1.5em;
}

.videoTutsButton {
  .Polaris-Button__Text {
    color: #5dc001;
  }
  svg {
    fill: #5dc001 !important;
  }
}

.videoTutsDiv {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

.padRight5 {
  padding-right: 5rem;
}

.embedNote {
  color: #5ec127;
  font-weight: 500;
}

.superLemonColor {
  color: #5ec127;
}

.embedButton {
  padding-top: 15px;
}

.sl-logo-login {
  width: 278px;
  height: 58px;
  zoom: 0.8;
}

.signUp {
  margin-top: 20px;
}

.appStore {
  margin-top: 20px;
  font-weight: 500;
}

@media all and (max-width: 480px) {
  .sl-logo-login {
    zoom: 0.6;
    position: relative;
    right: 20%;
  }
}

.customForm__ErrorMessage {
  padding-top: 1%;
  color: red;
}

.customForm__w-50 {
  width: 100%;
}

// Sidebar customization CSS

.sideBarSub {
  background-color: #edfae6;
  margin: 0px 10px 10px 10px;
  padding-top: 10px;
  border-radius: 8px;

  .Polaris-Navigation__Item--selected {
    background-color: white;
  }

  .Polaris-Badge--statusNew {
    background-color: #487f1a !important;
    color: #ffffff !important;
  }
}

.requiresSubText {
  color: #3e7f1a;
  margin-bottom: 10px;
  padding-left: 10px;
}

.Polaris-Badge--statusNew {
  background-color: #edfae6 !important;
  color: #3e7f1a !important;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation {
    max-width: calc(25.5rem + env(safe-area-inset-left)) !important;
  }
}

.Polaris-Navigation {
  min-width: 25.5rem !important;
}

.ribbonMessage {
  background-color: #f9d15f;
  font-size: 1em;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ribbonMessageRed {
  background-color: #ff6428;
  font-size: 1em;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}

.ribbonClose {
  cursor: pointer;
}

.Polaris-PositionedOverlay--fixed {
  right: 24.6px !important;
}

.nudgeScreenWix_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nudgeScreenWix_Imagecontainer {
  overflow: hidden;
  margin-top: -50px;
}

.walletAddBalance_checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.nudgeScreenWix_button {
  padding: 10px 20px 10px 20px;
  background: #5ec127 !important;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border: solid 1px;
  margin-right: 85px;
}

.nudgeScreenContactSupport_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
  gap: 50px;
}

.nudgeScreenContactSupport_Imagecontainer {
  overflow: hidden;
  margin-top: -50px;
}

.nudgeScreenContactSupport_button {
  padding: 10px 50px 10px 50px;
  background: #5ec127 !important;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 16px;
  border: solid 1px;
}

.font-weight-500 {
  font-weight: 500;
}

.flatGreen_btn {
  padding: 10px 20px 10px 20px;
  background: #5ec127 !important;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border: solid 1px;
}

.flatGreenOutline_btn {
  padding: 10px 20px 10px 20px;
  background: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border: solid 2px;
  border-radius: 5px;
  color: #5ec127;
}

.no-waba-modal-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-waba-modal-img {
  zoom: 0.5;
}

.planRecharge_cbx_container {
  display: flex;
  justify-self: center;
  gap: 10px;
}

.initials-avatar-container {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  display: inline-block;
  box-sizing: border-box;

  .xlarge {
    width: 70px;
    height: 70px;
    font-size: 4rem;
  }

  .large {
    width: 45px;
    height: 45px;
    font-size: 3rem;
  }

  .small {
    width: 40px;
    height: 40px;
    font-size: 2.2rem;
  }
}

.initials-avatar {
  color: #fff;
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: inherit;
  font-weight: inherit;
}

@media (max-width: 550px) {
  .initials-avatar-container {
    .xlarge {
      width: 40px;
      height: 40px;
      font-size: 2.5rem;
    }

    .large {
      width: 35px;
      height: 35px;
      font-size: 2rem;
    }
  }
}

@media (max-width: 420px) {
  .initials-avatar-container {
    .large {
      font-size: 1.8rem;
      width: 30px;
      height: 30px;
    }
  }
}

.FilesDragAndDrop__templateChat {
  .FilesDragAndDrop__area {
    width: 100%;
    height: 100px;
    padding-left: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    font-size: 15px;
    color: #555555;
    border: 2px #c3c3c3 dashed;
    border-radius: 10px;
    margin-top: 5px;
    gap: 20px;

    .area__icon {
      font-size: 64px;
      margin-top: 20px;
    }
  }
}

.templateChat-file-button {
  font-weight: 500;
  color: #5ec127;
  cursor: pointer;
}

.FilesDragAndDrop__importChats {
  .FilesDragAndDrop__area {
    width: 100%;
    height: 300px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 24px;
    color: #555555;
    border: 2px #c3c3c3 dashed;
    border-radius: 22px;
    margin-top: 20px;
    gap: 20px;

    .area__icon {
      font-size: 64px;
      margin-top: 20px;
    }
  }
}

#supportchatwidget .circular-widget {
  bottom: 72px;
  right: 55px;
}

.old-free-plan-modal-container {
  .planCardNew {
    width: 45%;
  }
}

.deleteSheduledCampaign {
  .Polaris-Button__Text {
    font-size: large !important;
    font-weight: 400;
  }
  .Polaris-ButtonGroup__Item {
    button {
      border-radius: 2rem !important;
    }
  }
}
