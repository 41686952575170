body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.previewBtnBackground {
  background-color: #f4f6f8;
  max-width: 300px;
  padding: 1.2rem;
  display: flex;
  align-items: center;
}

.previewBackground.greetings-templates .Polaris-Stack .Polaris-Stack__Item {
  margin-top: 2rem;
}

.btn-in-input {
  /* height: 1.6rem;
  width: 3.2rem;
  border-radius: 4px;
  border-color: #d2d2d2 !important; */
  /* -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0 0 5px 0 #d3d3d3; */
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: solid white 1px;
}

.wid85 {
  width: 85%;
}

.wid10 {
  width: 10%;
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .previewBackground.chat-btn-templates .Polaris-Stack .Polaris-Stack__Item {
    width: 45%;
  }
}

@media screen and (max-width: 568px) {
  .previewBackground.chat-btn-templates .Polaris-Stack .Polaris-Stack__Item {
    width: 45%;
  }
}

.templateItem {
  margin-left: 1px;
}

.tmplinput {
  margin-left: 0px;
}

.tmplimg {
  vertical-align: top;
  margin-right: 10px;
}

.smallImg {
  width: 70px;
}

.custom-avatar-url-cont {
  display: flex;
  align-items: flex-start;
}

.toolTip {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  width: 30px;
}

/**
* GREETINGS PREVIEW
*/

.wa-chat-bubble-floating-popup {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  background: #fff;
  overflow: hidden;
  width: 188px;
  max-width: 100%;
  font-family: sans-serif;
  bottom: 20px;
  display: block;
  margin: 0px 6px;
  border-radius: 0px;
}

.wa-chat-bubble-header-common {
  text-align: left;
  color: #fff;
  padding: 13px;
  background: linear-gradient(110.56deg, #20802c 0%, #30bf42 100%);
}

.wa-chat-bubble-header-common.wavy::after {
  content: '';
  position: absolute;
  width: 188px;
  /* left: 0; */
  margin-left: -13px;
  border-image-source: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 24 150 28'%3E%3Cdefs%3E%3Cpath id='gentle-wave' d='M-160 46c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'%3E%3C/path%3E%3C/defs%3E%3Cg%3E%3Cuse xlink:href='%23gentle-wave' x='30' y='3' fill='%23fff'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
  border-image-slice: 0 2 100%;
  border-bottom: solid 13px;
}

.wa-chat-bubble-header-title {
  font-size: 16px;
}

.wa-chat-bubble-header-desc {
  margin-top: 6px;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  letter-spacing: 0.15px;
  line-height: 100%;
  text-align: left;
  color: #efefef;
  /*padding-right: 40px; */
}

.wa-chat-bubble-avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  overflow: hidden;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #20802c;
}

.wa-chat-bubble-avatar img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.wa-chat-bubble-chat .list-cs {
  font-size: 0.6rem;
  padding: 0.7rem;
  overflow: hidden;
  border-bottom: 2px solid #fafafa;
  display: flex;
  background: #fff;
  cursor: pointer;
  align-items: center;
}

.whiteBG {
  background: #fff;
}

.wa-chat-bubble-chat .list-cs:last-child {
  margin-bottom: 1.2rem;
}

.wa-chat-bubble-profile-name {
  display: block;
  font-size: 1.15rem;
  margin-block-start: 0.3em;
  margin-block-end: 0.2em;
}

.wa-chat-bubble-cs-profile p {
  font-size: 0.6em;
}

.wa-chat-bubble-whatsapp-avatar {
  position: relative;
  vertical-align: bottom;
  left: 1px;
  z-index: 99;
  height: 1rem;
  width: 1rem;
}

.wa-chat-bubble-cs-profile {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  color: #000;
  font-size: 14px;
  margin-top: 2px;
  text-align: left;
  flex: 1;
}

.wa-chat-bubble-cs-profile p {
  margin: 0;
  padding: 0;
  line-height: 1;
  color: #555555;
  font-weight: 200;
}

.wa-chat-widget-footer {
  display: flex;
  bottom: 0px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* font-family: Open Sans; */
  font-family: 'Lato', Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 5px;
  color: #999999;
  width: 100%;
  height: 10px;
  background: #fafafa;
  vertical-align: middle;
  cursor: pointer;
  z-index: 100;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 1px 1px;
}

.wa-chat-bubble-chat {
  padding-bottom: 1.8rem;
}

.wa-chat-widget-footer-superlemon {
  color: #2eb840;
}

.wa-intercom .wa-chat-bubble-chat {
  margin: 0.9rem;
  margin-top: -1.8rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.wa-intercom .wa-chat-bubble-chat .list-cs:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.wa-intercom .wa-chat-bubble-chat .list-cs {
  /* padding: 16px 4px; */
}

.wa-intercom .wa-chat-widget-footer {
  box-shadow: none;
  background: transparent;
}

.wa-intercom .wa-chat-bubble-header-common {
  padding-bottom: 26px;
}

/***
* Share Btn Preview
*/
.wa-share-btn-container {
  width: auto;
  height: auto;
  margin: 0px;
  bottom: 50%;
  font-size: 13px;
  cursor: pointer;
  padding: 1px 4px 1px;
  box-shadow: 0px 0px 5px 0px #d3d3d3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.wa-share-btn-cta {
  writing-mode: tb;
  margin: 0;
  margin-bottom: 6px;
  margin-top: 6px;
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotate(-180deg);
  -webkit-writing-mode: tb;
  -ms-writing-mode: tb;
  display: table;
  color: white;
}

.wa-share-btn-img {
  display: table;
  height: 19px;
  width: 19px;
  margin: auto;
  margin-bottom: 4px;
}

.wa-share-icon {
  background: white;
}

.wa-share-mask {
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  -webkit-mask-image: url(https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa.svg?v=1586952948);
}

.previewCustomShareBtnBackground::-webkit-scrollbar {
  display: none;
}

.previewCustomShareBtnBackground {
  background-color: #f4f6f8;
  max-width: 350px;
  display: flex;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*
@media screen and (min-width: 568px) {
  .optin-number-page .Polaris-Popover__Content {
    min-width: 516px;
    min-height: 490px;
  }
}
*/

@media screen and (max-width: 568px) {
  .discountCodeColumn {
    width: min-content;
  }
}

.tutorialButton > button {
  background: none !important;
  border-radius: 2rem !important;
  border-color: #5ec127 !important;
  color: #5ec127 !important;
  box-shadow: none !important;
  margin-right: 10px;
}

.tutorialButton .Polaris-Icon__Svg {
  fill: #5ec127 !important;
}
.flexCenter {
  display: flex;
  justify-content: center;
}
.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}
.flexSpaceAround {
  display: flex;
  justify-content: space-around;
}
.div {
  color: #fff;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  padding: 10px;
}
.container {
  display: flex;
  border-radius: 4px;
  border: solid rgb(201 204 207) 1px;
}
.fixed {
  background-color: #f3f4f6;
  width: 100px;
  padding: 8px;
  font-size: 1.4rem;
  border-radius: 4px 0px 0px 4px;
  border: solid rgb(201 204 207) 1px;
  color: rgb(32 34 35);
}
.flex-item {
  padding: 8px;
  font-size: 1.4rem;
  border-radius: 4px;
}
.genericToastContainer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 70;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 0% !important;
  height: 100%;
  justify-content: center;
  display: flex;
}

.genericToastContainer--inner {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 230px;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.labelMargin {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;

  .Polaris-ButtonGroup__Item button {
    border-radius: 2rem !important;
  }
}

.createCampaignButtons {
  margin-top: 20px;
}
.discountCodeColumn a {
  color: black;
  text-decoration: none;
  font-weight: 600;
}
.discountCodeColumn a:hover,
.discountCodeColumn a:hover {
  color: black;
  text-decoration: none;
  font-weight: 600;
  outline: none;
}
.createNewCampaign .Polaris-Button--primary.Polaris-Button--disabled {
  color: white !important;
}

.create-custom-template .Polaris-Layout {
  margin: 10px;
}
.link-color {
  color: #0000cc;
}
.displayFlex {
  display: flex;
  gap: 1rem;
}
.table-list {
  margin-top: 3rem;
  background: #fafbfd;
  border-bottom: 2px solid #e4e4e7;
}
.table-list table thead tr th {
  font-weight: 600;
}
.mb2 {
  margin-bottom: 2rem;
}
.autoamted-response-form {
  display: flex;
  gap: 5rem;
  flex-direction: column;
}
.display-flex-center {
  display: flex;
  justify-content: center;
  padding: 2rem 0rem;
}
.release-notes img {
  width: 90%;
  margin: 2rem 0rem 2rem 3rem;
}
.release-notes a {
  text-decoration: underline;
  color: #1400ff;
}
.release-notes a:hover {
  text-decoration: underline;
  color: #1400ff;
}
.title-bold {
  font-weight: 600;
  text-align: center;
}
.display-flex-column-center {
  display: flex;
  flex-direction: column;
  padding: 5rem;
}
.pad2rem {
  padding: 1rem;
}
.payment-failed-message {
  color: #6d7175;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}
.payment-button {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

@media (max-height: 700px) {
  .genericToastContainer--inner {
    top: 70px;
  }
}
