@import "../../assets/css/variables.scss";

@media (max-width: 600px) {
  .need-help {
    .need-help-info {
      max-width: 26rem;
    }

    .Polaris-ButtonGroup {
      margin-top: 1rem;
    }
  }
}
a {
  text-decoration: none;
}
.cod {
  background-color: #f4f6f8;
  .Polaris-Header-Title__TitleAndSubtitleWrapper,
  .Polaris-SkeletonPage__Title {
    text-align: center;
  }
  .Polaris-Subheading {
    color: $primary-color !important;
  }
}
.powered-by {
  text-align: center;
  margin-top: 3rem;
  font-style: bold;

  .logo {
    width: 15rem;
    height: 3rem;
  }
}
.superlemon {
  color: $primary-color;
}
.not-found {
  text-align: center;
}
.order-name {
  font-size: 2.6rem;
  font-weight: bold;
}
.order-date {
  margin-left: 1.2rem;
  color: #637381;
  font-size: 1.4rem;
}
.Polaris-Button--primary.Polaris-Button--disabled {
  background: linear-gradient(to bottom, #00755780, #00755780) !important;
  border-color: #02574280 !important;
}
.rtl-language {
  direction: rtl;
}
.ltr-language {
  direction: ltr !important;
}
.Polaris-Button--primary svg {
  fill: $primary-color !important;
}
.need-help {
  position: relative;
  border: 1px solid #a5b4cb;
  border-radius: 1rem;
  padding: 3rem 0;
  .Polaris-ButtonGroup {
    justify-content: center;
  }
}
.need-help-info {
  font-weight: 500;
  letter-spacing: 0.2px;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  background-color: #e5ecf4;
  border-radius: 1rem;
  padding: 0.4rem 1rem;
}
.item-description {
  max-width: 84% !important;
}

.product-details {
  max-width: 77% !important;
  p {
    word-break: break-word;
  }
}
.product-amount {
  margin-left: 0 !important;
  justify-self: flex-end;
}

.lineChartContainer {
  border: 1px solid #f6f8f8;
  border-radius: 10px;
  background: #fafdfd;
  h2 {
    margin: 20px 0px 20px 30px;
  }
}
