.boldText {
  font-weight: 600;
  font-size: 1.5rem;
}
.mr-bt-2 {
  margin-bottom: 2rem;
}
.mr-tb-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.displayFlex {
  display: flex;
  gap: 10rem;
}
.wid25 {
  width: 25%;
}
.sl-card {
  box-shadow: var(
    --p-card-shadow,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  );
  border-radius: 3px;
  background-color: #ffffff;
  margin-top: 5rem;
  padding: 2rem;
}
.sl-card-title {
  font-weight: 500;
}
.sl-card-descp {
  width: 70%;
}
.sl-away-message {
  margin: 1rem 0rem;
  padding: 2rem 2rem;
  background: #ffffff;
  border: 1px solid #d1c9c9;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.Polaris-ButtonGroup__Item button {
  border-radius: 0.5rem !important;
}

.agent_assist_working_hours_input {
  border: 1px solid lightgray;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
}

.chat__ConfigurationButtonSave {
  justify-content: center;
  display: flex;
}

.chat__ConfigurationButtonSave button {
  border-radius: 0.5rem !important;
}

.chat__configurationModalHeading {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 30px;
}

.Polaris-ActionList__Text {
  white-space: pre-wrap;
}

.font2rem {
  font-size: 2rem !important;
}
