.basic-learnMore-modal {
  display: flex;
  flex-flow: column;
}

@import url("../../../assets/fonts/MontserratAlternates.css");
*,
*:before,
*:after {
  box-sizing: border-box;
}

@mixin flag(
  $bg: #72879a,
  $color: #fff,
  $direction: right,
  $tail-length: 0.8em,
  $build-flag: true
) {
  // ======= Relative Variables =======
  $p: 0.2em;

  // ======= Flag Body =======
  @if $build-flag {
    // ~~ Display ~~
    display: inline-block;
    // ~~ Box Layout ~~
    padding: $p;
    padding-#{$direction}: $p;
    // ~~ Positioning ~~
    margin-#{$direction}: $tail-length;
    position: relative;
    // ~~ Typography ~~
    text-align: center;
    vertical-align: middle;
    line-height: 1;
  }
  // ~~ Themeing ~~
  color: $color;
  background: $bg;

  // ======= Flag Tails =======
  &:before,
  &:after {
    @if $build-flag {
      content: "";
      width: 0;
      height: 0;
      border-#{$direction}: $tail-length solid transparent;
      #{$direction}: -#{$tail-length};
      position: absolute;
      top: 0;
    }
    border-top: (0.5em + $p) solid $bg;
  }
  &:after {
    @if $build-flag {
      top: auto;
      bottom: 0;
    }
    border-top: none;
    border-bottom: (0.5em + $p) solid $bg;
  }
}

.flag {
  @include flag($bg: red);
}

.flagContainer {
  position: relative;
  left: 5px;
}
