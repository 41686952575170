@import "../../assets/css/variables.scss";

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }
}

.basic-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  width: 375px;
}

.pricing-heading-css {
  font-size: 32px;
  font-weight: 600;
  opacity: 0.9;
  width: 70%;
  line-height: 35px;
}
.pricing-subHeading-css {
  font-size: 14px;
  line-height: 21px;
}

.upgradePlanCss {
  margin-top: 40px;
  background-color: #eff6ed;
  opacity: 0.9;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  align-items: center;

  .title {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
}

.pricingButtonCss {
  width: 115.43px;
  height: 40px;
  background: #5ec127 !important;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border: solid 1px;
}

.headingCss {
  margin-top: 20px !important;
  margin-left: 50px !important;
  max-width: 40% !important;
  .heading {
    margin-top: 13rem;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
  .content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .IconCss {
    width: 500px;
    height: 400px;
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  .buttonCss {
    width: 115.43px;
    height: 40px;
    background: #5ec127 !important;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    border: solid 1px;
    margin-top: 5px;
  }
  .left-row-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 500px) {
    max-width: 80% !important;
  }

  @media screen and (min-width: 900px) {
    max-width: 35% !important;
  }
}

.UpgradeIconCss-box {
  width: 20rem;
  .UpgradeIconCss {
    width: 40rem;
    position: relative;
    left: 20px;
  }

  @media screen and (max-width: 329px) {
    .UpgradeIconCss {
      position: relative;
      left: 40px;
      width: 25rem;
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 330px) {
    .UpgradeIconCss {
      position: relative;
      left: 60px;
      width: 25rem;
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 400px) and (max-width: 500px) {
    .UpgradeIconCss {
      width: 30rem;
      position: relative;
      left: 80px;
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    .UpgradeIconCss {
      width: 30rem;
      margin-top: 55px;
      position: relative;
      left: -20px;
    }
  }

  @media screen and (min-width: 900px) {
    .UpgradeIconCss {
      position: relative;
      left: 60px;
      width: 45rem;
      margin-top: 10px;
    }
  }
}
