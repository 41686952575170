.font2rem {
  font-size: 2rem;
}
.marB10 {
  margin: 1rem;
  margin-bottom: 5rem;
}
.invoice-main-container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem;
}
.home-image {
  width: 20rem;
  height: 7rem;
}
.home-address {
  margin: 1rem 0rem;
}
.home-header {
  margin: 2rem 0rem;
  display: flex;
  justify-content: space-between;
}
.textColor {
  color: rgba(0, 0, 0, 0.65);
}
.home-text2 {
  color: #2091de;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.customer-container {
  display: flex;
  justify-content: space-between;
}
.customer-details {
  display: flex;
  flex-direction: column;
  color: #060606c4;
  font-weight: 600;
}
.invoice-details {
  display: flex;
  flex-direction: column;
  color: #060606c4;
  font-weight: 600;
  width: 35%;
}
.other-details {
  display: flex;
  flex-direction: column;
  color: #0000008a;
  margin-top: 2rem;
}
.greetings {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#customers td,
#customers th {
  padding: 1rem;
}
#customers tr td {
  background-color: #f2f2f2;
  border: 1px solid #e3e2e2d4;
}
// #customers tr:hover {background-color: #ddd;}
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: #100f0f;
}
.invoice-table {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.right-text {
  text-align: right;
  background-color: #fff !important;
}
.account-details {
  border: 2px solid;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: 600;
  width: 60%;
}
.account-details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.fontWeight400 {
  font-weight: 400;
}
.invoiceLine {
  border: none;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 3rem;
}
.invoiceLineSmall {
  border: none;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 1rem;
}
.invoice-details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.invoice-details-tophalf {
  margin: 1rem 0rem;
}
.amt-font {
  font-size: 1.75rem;
}
