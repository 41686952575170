.myWhatsAppPageContainer {
  display: flex;
  flex-direction: column;
}

.wabaOverviewContainer {
  display: flex;
  padding: 20px;
  margin: 10px 0px;
  gap: 2rem;
}

.wabaStatusSection {
  display: flex;
  flex-direction: column;
  border: 1px solid #dbf5d6;
  border-radius: 8px;
  padding: 1rem;
  width: 32%;
  height: auto;

  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;

    .iconImage {
      width: 3rem;
      height: 3rem;
    }
  }

  .subheading {
    font-size: 14px;
    height: auto;
    a {
      color: #5ec127;
    }
  }

  .wabaStatusTag {
    height: 3.5rem;
    margin: 1.5rem;
    display: flex;
    align-items: center;
    border-radius: 2rem;
    box-shadow: none;
  }

  .wabaStatusTagValue {
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-weight: 500;
  }

  .greenColor {
    background-color: #e1f6d5;
    color: #5ec127;
  }

  .blueColor {
    background-color: #e5eeff;
    color: #0087ee;
  }

  .orangeColor {
    background-color: #ffe2cc;
    color: #ed6c02;
  }
}

.wabaDetailsContainer {
  display: flex;
  padding: 20px;
  margin: 10px 0px;
  width: 100%;
}

.wabaBussAccountDetailsContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #dbf5d6;
  border-radius: 8px;
  padding: 1rem;
  height: auto;
  width: 100%;

  .headingSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    .heading {
      margin-top: 0rem;
    }
  }

  .editSettingLink {
    display: flex;
    align-items: center;
    color: #0087ee;
    gap: 0.5rem;
    cursor: pointer;

    svg {
      fill: #0087ee;
    }
  }

  .wabaBussAccountInfoContainer {
    display: flex;
    .wabaBussAccountPicture {
      width: 20%;
    }
    .wabaBussAccountInfo {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      padding: 1rem 0rem 0rem 3rem;
    }
    .accountInfoEachSection {
      width: 33%;
      padding: 1rem;
    }
    .displayPicture {
      color: #898888;
      padding: 0.5rem;
    }
  }

  .wabaBussAccountOtherDetails {
    display: flex;
    .wabaBussAccountInfo {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 1rem 0rem 0rem 3rem;
    }
    .accountInfoEachSection {
      width: 25%;
      padding: 1rem;
    }
    .accountInfoEachSectionTitle {
      padding: 1rem;
      width: 100%;
      font-weight: 600;
    }
  }

  .customButton {
    span {
      font-weight: 600;
    }
  }
}

.fontColorGrey {
  color: #6d7175;
}

.borderLeft {
  border-left: 3px solid #5ec127;
  border-radius: 10px;
}

.boldFont {
  font-weight: 600;
  color: #202223;
}

.boldFontBlue {
  font-weight: 600;
  color: #0087ee;
}

.marginTB {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.note {
  margin-top: 5px;
  color: #898888;
}

.backgroundImage {
  background: radial-gradient(
      54.08% 173.75% at 94.31% 39.43%,
      rgba(255, 255, 255, 0.6) 0%,
      #ffffff 100%
    ),
    url("../../../assets/images/MyWhatsappPageBackg.png"), #ffffff;
}

.headingGreen {
  color: #5ec127;
}

/* On screens that are 550 or less, set the background color to olive */
@media screen and (max-width: 550px) {
  .wabaOverviewContainer {
    flex-direction: column;
  }
  .wabaStatusSection {
    width: 100%;
  }
  .wabaBussAccountPicture {
    width: 45% !important;
  }
  .wabaBussAccountInfoContainer .wabaBussAccountInfo {
    flex-direction: column;
  }
  .accountInfoEachSection {
    width: 50% !important;
  }
  .hmin {
    width: 85% !important;
  }
}
