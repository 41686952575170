.mainDiv {
  display: flex;
}

.secondMainDiv {
  margin-left: 20px;
}

.secondDiv {
  margin-top: 20px;
  margin-left: 11px;
}

.secondText {
  font-weight: 500;
  font-family: "Rubik", sans-serif ;
}

@media screen and (max-width: 400px) {
  .mainDiv {
    display: block;
    .inMainDiv {
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Stack {
            .Polaris-Stack__Item {
              width: 240px;
            }
          }
        }
      }
    }
    .secondMainDiv {
      margin-left: 0%;
      .secondDiv {
        margin-left: 0%;
      }
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Select {
            margin-left: 0%;
            width: 240px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) and (min-width: 400px) {
  .mainDiv {
    display: block;
    .inMainDiv {
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Stack {
            .Polaris-Stack__Item {
              width: 350px;
            }
          }
        }
      }
    }
    .secondMainDiv {
      margin-left: 0%;
      .secondDiv {
        margin-left: 0%;
      }
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Select {
            margin-left: 0%;
            width: 350px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) and (min-width: 601px) {
  .mainDiv {
    display: block;
    .inMainDiv {
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Stack {
            .Polaris-Stack__Item {
              width: 200px;
            }
          }
        }
      }
    }
    .secondMainDiv {
      margin-left: 0%;
      .secondDiv {
        margin-left: 0%;
      }
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Select {
            margin-left: 0%;
            width: 480px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) and (min-width: 700px) {
  .mainDiv {
    display: block;
    .inMainDiv {
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Stack {
            .Polaris-Stack__Item {
              width: 470px;
            }
          }
        }
      }
    }
    .secondMainDiv {
      margin-left: 0%;
      .secondDiv {
        margin-left: 0%;
      }
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Select {
            margin-left: 0%;
            width: 470px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) and (min-width: 900px) {
  .mainDiv {
    .inMainDiv {
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Stack {
            .Polaris-Stack__Item {
              width: 180px;
            }
          }
        }
      }
    }
    .secondMainDiv {
      margin-left: 0%;
      .secondDiv {
        margin-left: 7%;
      }
      .Polaris-Stack {
        .Polaris-Stack__Item {
          .Polaris-Select {
            margin-left: 7%;
            width: 300px;
          }
        }
      }
    }
  }
}
