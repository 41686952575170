.business-campaign-window {
  padding-top: 16px;
  margin-top: 30px;
  box-sizing: border-box;
  background: radial-gradient(
      54.08% 173.75% at 94.31% 39.43%,
      rgba(255, 255, 255, 0.6) 0%,
      #ffffff 100%
    ),
    #ffffff;
  border: 1px solid #dbf5d6;
  border-radius: 8px;
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #202223;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 5px;
  }
  .planText {
    font-size: 16px;
    line-height: 21px;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 5px;
    margin-top: 5px;
    color: #202223;
    .planText-bold {
      color: #5ec127;
      font-weight: 600;
    }
  }
  .planSubTitle {
    font-weight: 400;
    line-height: 19px;
    color: #6d7175;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 5px;
    font-size: 14px;
  }
  .footerText {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #6d7175;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 5px;
  }
}

.whatsappScreen {
  img {
    padding: 0px 20px;
    max-width: 100%;
    max-height: 100%;
  }
}
.mr10 {
  margin-right: 10px;
}

.tieIconClass {
  float: left;
  img {
    height: 200px;
    width: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 25px;
    margin-right: 80px;
  }
}
