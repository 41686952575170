.metric_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    font-size: 1.5rem;
    margin-top: -2rem !important;
    display: block;
  }

  h2 {
    font-size: 3.5rem;
    font-weight: 500;
    margin-top: 2rem;
  }
}

.metric_icon {
  width: 7rem;
}

.text_tooltip {
  color: #fff;
}

.custom_tooltip {
  background-color: rgba(0, 0, 0, 0.705);
  border-radius: 0.75rem;
  padding: 0.45rem 1rem;
}

// .pie_chart_container{
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
// }
// .pie_chart_wrapper{
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 50%;
// }

@media (max-width: 1100px) {
  .pie_chart_container {
    flex-direction: column;
    align-items: center;
  }
}

.blockCont {
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.lineChartColorBlock {
  width: 16px;
  height: 16px;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.lineChartVariables {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
}

.analyticContainer {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lineChartLayoutContainer {
  background-color: #f9fafd;
  padding: 20px;
  margin: 10px 0px 20px;
  border-radius: 5px;
}

.blurBucket {
  position: absolute;
  width: calc(100% - 4rem);
  height: 250px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineChartContainer {
  width: 55%;
  padding: 20px;
  position: relative;
}

@media (max-width: 850px) {
  .lineChartMobileContainer {
    width: 100%;
    padding: 20px;
    margin-left: 1.2em;
  }
}

.lineChartLegendContainer {
  width: 45%;
  padding: 20px;
  margin: auto;
}

.otherMessagesChartContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
}

.otherMessagesChart {
  position: relative;
  width: 47%;
  margin: 0;
}

@media (max-width: 850px) {
  .lineChartLegendMobileContainer {
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 850px) {
  .otherMessagesChartMobileContainer {
    display: block;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 25px;
  }
}

@media (max-width: 850px) {
  .otherMessagesChartMobile {
    display: block;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 25px;
  }
}

.blurContainer {
  filter: blur(4px);
  pointer-events: none;
}

.contentContainer {
  margin-top: 10px;
}

.contentRow {
  display: flex;
  align-items: center;
}

.contentIcon {
  height: 26px;
  width: 26px;
  margin: 26px;
}

.conentOpc {
  opacity: 0.1;
}

.contentText {
  font-size: 12px;
}

.contentSubText {
  font-size: 14px;
  font-weight: bold;
}

.contentDisableText {
  color: grey;
}

.automation__links{
  color: blue;
}
.automation__links:hover{
  color: black;
}