.Polaris-Card {
  margin-top: 20px;
  margin-bottom: 20px;
}

.enableContainer {
  font-size: 16px;
}

.card {
  flex: 0 0 100%;
  padding: 0px;
  background: none;
  box-shadow: none;
}

.cards-wrapper {
  display: flex;
  transition: ease 0.5s;
}

.display-area {
  overflow-x: hidden;
}

.dots-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}

.dot {
  border: none;
  background: rgba(0, 0, 0, 0.2);
  width: 20px;
  height: 20px;
  margin: 5px;
  border-radius: 50%;
  outline: none;
}

.dot:hover {
  background: rgba(0, 0, 0, 0.3);
}

.dot.active {
  background: #71c227;
}
