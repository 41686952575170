.basic-insufficientBal-modal {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 10px;
  overflow: hidden !important;
  .insufficientBalModalHeading {
    line-height: 60px;
    color: #5ec127;
    font-weight: 600;
    font-size: 200%;
  }

  .insufficientBalModalContent {
    font-weight: 300;
    margin-top: 3%;
    position: relative;
    padding: 0% 10%;
  }

  .insufficientBalModalButtonCss {
    padding: 6px 6px;
    background: #5ec127 !important;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 16px;
    border: solid 1px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    width: 50%;
  }

  .insufficientBalModalFooter {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .insufficientBalModalHeading img {
    width: 45px;
    height: 45px;
    position: relative;
    right: 2%;
    top: 13px;
  }
}

@media all and (max-width: 610px) {
  .basic-error-modal {
    .insufficientBalModalFooter {
      position: relative;
      left: 28%;
      top: 45px;
    }
  }
}
