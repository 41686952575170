@import "../../../assets/css/variables.scss";

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f4f9f4;
}

.accordion-faq-content {
  overflow-y: auto;
  height: 250px;
}
.accordion-big-item {
  overflow: hidden;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #f4f9f4;
  margin-top: -1rem;
  font-weight: 300;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}

.basic-faq-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 100%;
}

.hrCss {
  margin-top: -1rem;
  margin-bottom: 3px;
}

.faqIconCss {
  width: 25rem;
  height: 25rem;
}
.faqLayout {
  display: flex;
  flex-direction: row;
  height: 500px;
}

.fadHeading {
  font-size: 32px;
  font-weight: 600;
  opacity: 0.9;
  width: 70%;
  line-height: 35px;
  position: relative;
  bottom: 15px;
}

.FAQ__question{
  font-weight: 900;
}

.FAQ_link{
  color: blue;
}