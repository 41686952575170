#content-wrapper {
  background-color: #ffffff;
  padding: 20px 10px;
  width: 300px;
  border-radius: 0.5rem;
}

.custom-template-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}
.create-custom-template {
  margin-top: 20px;
}
.custom-temp-body .Polaris-FormLayout__Item {
  position: relative;
}
.custom-temp-body .add-variable {
  bottom: 10px;
  z-index: 30;
  right: 10px;
}
.custom-temp-body .add-variable button {
  border-radius: 10px !important;
  background: #f5faf4;
  border: none;
}

.add-variable {
  position: relative;
  display: inline-block;
}
.variable-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0 10px 9px -5px rgb(0 0 0 / 20%);
  z-index: 1;
}
.add-variable:hover .variable-content {
  padding-top: 10px;
  display: block;
}
.add-variable:hover .variable-content div {
  padding: 10px;
  background-color: #f9f9f9;
}
.add-variable:hover .variable-content div:not(:last-child) {
  border-bottom: 1px solid #cbcbcb;
}
.add-variable .variable-content {
  max-height: 200px;
  overflow-y: auto;
}

.Polaris-Tooltip-TooltipOverlay__Content {
  color: black !important;
  background-color: #fff !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.inline-input .Polaris-Select__Backdrop,
.inline-input .Polaris-TextField__Backdrop {
  border-bottom: 2px solid var(--p-border-subdued) !important;
  border: none;
  background-color: transparent !important;
  min-width: 20%;
}
.inline-input .Polaris-Select__Content {
  padding-left: 0px;
}
.call-to-action h3 {
  margin-top: 1.6rem;
  font-weight: bold;
}
.call-to-action .call-action-close {
  margin-top: 1.6rem;
  margin-left: 10px;
}
.call-to-action .call-to-action-str {
  margin-top: 1rem;
  width: 20px;
  text-align: center;
  margin-left: 5px;
}
.call-to-action {
  margin-bottom: 15px;
}
.call-to-action .Polaris-TextField__Input {
  padding: 0.5rem 0rem;
}
.flex-box {
  display: flex;
  grid-gap: 15px;
  gap: 0px;
  align-items: center;
}
.list-unstyled ul {
  padding: 0;
  list-style-type: none;
}
.testing {
  color: red;
}

.textField .Polaris-InlineError {
  position: absolute;
}

ul.tooltip-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    &:not(:last-child) {
      padding-bottom: 8px;
    }
  }
}

@media screen and (max-width: 992px) {
  .call-to-action.inline-input .Polaris-FormLayout__Item {
    min-width: 5rem;
    margin-left: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  .call-to-action.inline-input .Polaris-FormLayout__Item {
    min-width: 16rem;
    margin-left: 1.1rem;
  }
  .call-to-action {
    display: block !important;
  }
  .call-to-action .flexCenter {
    display: block !important;
  }
}

#tempBody {
  padding-bottom: 50px;
  scroll-padding-bottom: 40px;
}

@media all and (min-width: 1069px) and (max-width: 1122px) {
  .customButtonCss {
    margin-top: 1rem;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.category-stack {
  margin-bottom: 4rem !important;
  max-width: 100%;
  display: flex;
}

.template-filter {
  width: 75%;
  display: flex;
  align-items: center;
}

.mr-1rem {
  margin-right: 1rem;
}

.Polaris-Choice {
  padding: 0rem 0.5rem 0 !important;
}

@media screen and (max-width: 850px) {
  .template-filter {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .template-filter {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1200px) {
  .custom-temp-body .add-variable {
    bottom: 23px !important;
  }
}
