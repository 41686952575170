.planModalWix {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 20px 0px 20px;
}

.planModalWix_headingComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
}

.planModalWix_leftC {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
}

.planModalWix_rightC {
  display: flex;
  flex-direction: row;
  color: #5ec127;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  gap: 10px;
}

.planModalWix_heading {
  font-size: 2.5rem;
  font-weight: 500;
}

.planModalWix_price {
  font-size: 3rem;
}

.planModalWix_validity {
  padding-top: 10px;
}

.planModalWix_features {
  width: 100%;
}

.planModalWix_featuresColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
}

.planModalWix_featuresHeading {
  padding-top: 5px;
  font-size: 2.25rem;
  padding-bottom: 5px;
}

.planModalWix_ListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 5px;
}

.planModalWix_pricing {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.planModalWix_pricingHeading {
  padding-top: 15px;
  font-size: 2.25rem;
  padding-bottom: 5px;
}

.planModalWix_Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  width: 100%;
}

.planModalWix_Button {
  padding: 10px 10px;
  background: #5ec127 !important;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 16px;
  border: solid 1px;
  flex-grow: 1;
}
