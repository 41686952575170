.basic_info_plans .basic-tile {
  max-width: 300px;
}

.rightColumnContent {
  padding: 20px;
}

.rightColumnContent-otp {
  padding: 20px;
  margin-left: 90px;

  @media only screen and (max-width: 990px) {
    margin: 0%;
    margin-top: 40px;
  }

  .bottomRow-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .Polaris-Button__Text {
      color: #71c227;
    }

    .Polaris-Button--disabled .Polaris-Button__Text {
      color: grey;
    }
  }
}

.otpField {
  .Polaris-TextField__Input {
    border-color: #71c227;
    border-radius: 4px;
    border-width: 2px;
    width: 38px;
  }
  .Polaris-Connected__Item {
    padding-left: 10px;
  }
}

.requestSubmitted_text {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}

.greenLine {
  width: 600px;
}

.modalHeading {
  color: #71c227;
  font-size: large;
}

.spinnerMid {
  display: flex;
  align-items: center;
  justify-content: center;
}
