.plan-modal-title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 1rem;
}

.plan-modal-title-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.free-trial-text {
    border: solid 1px #5EC127;
    padding: 0.3rem;
    color: #5EC127;
    font-weight: 500;
}