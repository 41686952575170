.leftColumn {
  max-width: 500px;
}

.planNameNew {
  color: #71c227;
}

.planNameOld {
  color: orange;
}

.planStatusOld {
  background-color: orange;
  border-radius: 10px;
  font-size: 16px;
}

.planStatusNew {
  background-color: #71c227;
  border-radius: 10px;
  font-size: 16px;
}

.planColorNew {
  background-color: #ebf9e4;
  border-radius: 10px;
}

.planColorOld {
  background-color: #f7f8f8;
  border-radius: 10px;
}

.Polaris-Card {
  margin-left: 5px;
}

.close {
  float: right;
  padding: 5px 6px;
}
