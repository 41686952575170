.templateItem {
  margin-left: 1px;
}
.templateItem.optins .wa-chat-bubble-floating-popup img {
  width: auto !important;
}
.templateItem.optins input {
  margin-right: 1.3rem;
}
.templateItem.optins img {
  width: 192px;
  max-width: 100%;
}
.templateItem.share-template-btns input {
  margin-right: 0.8rem;
}
.optins-widget-setting-toggle {
  margin: 2rem 0;
}
.optins-widget-setting-toggle .Polaris-SettingAction {
  align-items: normal;
}
.optins-widget-setting-toggle .Polaris-SettingAction .previewBtnBackground img {
  width: 75%;
}

.wa-optin-widget-input-box {
  display: flex;
  place-items: center;
  background: #ffffff !important;
  border: 1px solid #c4cdd5;
  box-shadow: inset 0px 1px 2px rgba(102, 113, 123, 0.21);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.wa-optin-widget-input,
input[type="tel"].wa-optin-widget-input {
  border: 0 !important;
  padding: 0.3rem 0.3rem !important;
  background: transparent !important;
  width: 100% !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 350 !important;
  line-height: 1 !important;
  margin: 0 !important;
  color: black !important;
  min-height: 0px;
  height: auto;
}

.wa-optin-clickButton {
  position: relative;
  left: 60%;
  bottom: 0;
  cursor: pointer;
}

.wa-optin-preview-input-box {
  width: 60%;
  position: relative;
  left: 15px;
  top: 17px;
  border-radius: 25px;
}

#wa-optin-clickButton-container {
  position: relative;
  bottom: 8px;
  left: 73%;
  width: 25px;
  height: 25px;
  overflow: hidden;
}

#wa-optin-clickButton-container img {
  width: 100%;
}
