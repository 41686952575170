.basic-approval-modal {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 10px;
  .approvalModalHeading {
    line-height: 60px;
    color: #5ec127;
    font-weight: 600;
    font-size: 18px;
  }
  .approvalModalContent {
    font-weight: 300;
    width: 60%;
    margin-top: 20px;
  }
  .approvalModalButtonCss {
    padding: 8px;
    height: 40px;
    background: #5ec127 !important;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border: solid 1px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.approvalModalFooter {
  position: absolute;
  right: 20px;
  bottom: 10px;
}
