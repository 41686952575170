@import url("../../../assets/fonts/Rubik.css");

.SetupWarnCard {
  background: #fffbeb;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0px;

  .mainTitle {
    color: #202223;
    font-family: "Rubik", sans-serif ;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
  }

  .mainTitle .green {
    color: #5ec127;
    font-family: "Rubik", sans-serif ;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-left: 6px;
    line-height: 24px;
  }

  .desc {
    font-family: "Rubik", sans-serif ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 15px;
    color: #6d7175;
  }

  .Polaris-Stack__Item
    .Polaris-Stack
    .Polaris-Stack__Item
    .whatsapp
    .Polaris-Select {
    margin-top: -4px;
    margin-left: 10px;
    width: 150px;
  }

  .whatsapp {
    margin-left: -10px;
  }

  .Polaris-Connected {
    margin-top: -4px;
  }

  .Polaris-Stack {
    display: flex;
    margin-bottom: 8px;
    margin-top: -1%;
    margin-right: 0;

    .Polaris-Select {
      margin-top: -6px;
      margin-left: 10px;
      width: 390px;
    }

    .insideFClass {
      .labelText {
        font-family: "Rubik", sans-serif ;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-top: 20px;
      }

      .selectAreaF {
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;

        .whatsapp {
          padding: 12px;
          gap: 10px;
          width: 80px;
          height: 41px;
          background: #ffffff;
          border: 1px solid #e1e3e5;
          border-radius: 5px;

          &:invalid {
            font-family: "Rubik", sans-serif ;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #e1e3e5;
          }
        }

        .whatsappNum {
          padding: 12px;
          gap: 10px;
          width: 358.5px;
          height: 41px;
          background: #ffffff;
          border: 1px solid #e1e3e5;
          border-radius: 5px;
          margin-left: 10px;
        }
      }
    }

    .insideSClass {
      margin-left: 30px;

      .labelText {
        font-family: "Rubik", sans-serif ;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-top: 20px;
      }

      .selectAreaS {
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;

        .newselect {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 12px;
          gap: 10px;
          width: 448.5px;
          height: 42px;
          background: #ffffff;
          border: 1px solid #e1e3e5;
          border-radius: 5px;
          -moz-appearance: none !important;
          -webkit-appearance: none !important;
          appearance: none !important;
          padding-right: 2rem !important;

          &:invalid {
            font-family: "Rubik", sans-serif ;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #e1e3e5;
          }
        }
      }
    }
  }

  .btnClass {
    // width: 97px;
    padding-left: 20px;
    padding-right: 20px;
    height: 41px;
    background: #5ec127;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-weight: 700;
    margin-top: 17px;
  }

  .whatsappNum::placeholder {
    font-family: "Rubik", sans-serif ;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #e1e3e5;
  }
}
