.wabaHeader {
  font-weight: 600;
  font-size: 20px;
  color: #3f3f3f;
  line-height: 40px;
}
.wabaMainDiv {
  padding: 3rem 1rem 1rem 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 10px;
}
.wabaSubTitle,
.wabaKey,
.wabaValue {
  font-weight: 400;
  font-size: 14px;
  color: #3f3f3f;
  .Polaris-Select__Content {
    width: 190px;
  }
}
.wabaSubdiv {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0px;
}

.wabaKey,
.wabaValue {
  padding: 10px 0px;
  width: 45%;
  color: #3f3f3f;
}
.wabaToolTip {
  display: flex;
  align-items: center;
}
.wabaValue {
  width: 200px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-right: 10px;
}

.customButton button {
  background: #5ec127 !important;
  border: 1px solid #5ec127 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  color: white;
}

.customButtonGray button {
  border: 1px solid #5ec127 !important;
  color: #5ec127;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
}
.wabaDetails {
  background: #fafbfb;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0px;
  font-size: 14px;
  p {
    line-height: 20px;
  }
  strong {
    line-height: 40px;
  }
}

.quickOverviewMarkup {
  .flexStart {
    display: flex;
    justify-content: flex-start;

    .numbers {
      background-color: #5ec127;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: white;
      width: 20px;
      font-size: 10px;
      margin: 5px;
      position: absolute;
    }
    .insText {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 0px 5px 31px;
    }
  }
  .wabaActionButton {
    display: flex;
    margin: 30px 0px 10px 0px;
    padding-right: 1rem;
  }
  button {
    margin-right: 1rem;
  }
  .note {
    color: #6d7175;
  }
}

.flexEnd {
  justify-content: flex-end;
}
.wabaGOBACK {
  display: flex;
  justify-content: flex-start;
  position: relative;
  top: 70px;
}

.intoOverviewMarkup {
  border: 1px #dbf5d6 solid;
  margin-top: 5rem;
  display: flex;

  .introDocs {
    width: 70%;
  }
  .introImage {
    width: 30%;
    img {
      width: 100%;
    }
  }
  .descpText {
    margin: 5px 0px 5px;
  }
  .wabaActionButton {
    display: flex;
    margin: 30px 0px 10px 0px;
    padding-right: 1rem;
  }
  button {
    margin-right: 1rem;
    border: 1px solid #5ec127 !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    font-weight: 500;
  }
}
/* On screens that are 550 or less, set the background color to olive */
@media screen and (max-width: 550px) {
  .intoOverviewMarkup {
    flex-direction: column;
    .introDocs {
      width: 100%;
    }
    .introImage {
      width: 100%;
    }
  }
}
/* On screens that are 850 or less, set the background color to olive */
@media screen and (min-width: 560px) and (max-width: 850px) {
  .intoOverviewMarkup {
    .introDocs {
      width: 60%;
    }
    .introImage {
      width: 40%;
    }
  }
}

.faqData {
  background: #f5faf4;
  border-radius: 5px;
  margin: 10px 5px;
  padding-left: 10px;
  font-size: 14px;
  width: 80%;
  strong {
    font-size: 16px;
  }
  .flexSpaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    margin: 0px;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
}
.geyDottedLine {
  border-top: 1px dotted grey;
  padding-bottom: 10px;
}
.wabaButtonWidth {
  button {
    width: 140px;
    height: fit-content;
  }
}
.overViewFbButton {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px 10px 0px;
  flex-direction: column;
}

.wrapperNumber {
  display: flex;
  align-items: center;
  width: 90%;
}
.divider {
  flex-grow: 1;
  border-color: rgb(149, 155, 149);
}
.leftWABA,
.rightWABA {
  height: 20px;
  width: 20px;
  background: rgb(61, 255, 61);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.positionRelative {
  position: relative;
}
.positionAbsolute {
  position: absolute;
}
.absoluteTopRight {
  position: absolute;
  top: 30px;
  right: -20px;
}
.setupDiv {
  display: flex;
  justify-content: center;
}

.marginTop {
  margin-top: 10px;
}
.whatsappImage {
  margin: auto;
  width: 300px;
}
.spaceEven {
  display: flex;
  justify-content: space-evenly;
}
.gupshupInsText {
  font-size: 13px;
  background: #0e5d55;
  color: white;
  padding: 10px;
  border-radius: 4px;
}
.margin40 {
  margin: 40px;
}
.businessImageDiv {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}
.businessImage {
  width: 150px;
  margin: auto;
  border-radius: 50%;
  border: solid rgb(231, 231, 231) 3px;
  padding: 3px;
}
.businessDesc {
  color: #898888;
  padding: 10px;
  margin-top: 20px;
}
.remove {
  button,
  button:hover {
    margin: 10px;
    border-radius: 4px !important;
    background-color: #e74c3c;
    color: white;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}
.uploadImage {
  button,
  button:hover {
    margin: 10px;
    border: 1px solid #5ec127 !important;
    color: #5ec127;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
  }
}
.uploadMediaFile {
  button {
    background-color: #cacdcf;
    border-radius: 0.25rem !important;
    margin-right: 1rem;
  }
  button:hover {
    color: #3f3f3f;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  }
}
.fileTypeNote {
  padding-bottom: 2rem;
  color: #6d7175;
}
.businessIns {
  color: #898888;
  font-style: italic;
  font-weight: bold;
  padding: 10px;
}
.businessDetails {
  margin-top: 20px;
  color: #898888;
}
.wabaLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinLoader {
  svg {
    fill: #d5d1d1 !important;
  }
  img {
    width: 100%;
  }
}
.waba {
  .Polaris-Page .Polaris-Header-Title {
    font-size: 3.1rem;
    width: 80%;
    line-height: 40px;
  }
}
// .mb20 {
//   margin-bottom: 20px;
// }
.helpText {
  color: #4b4b4b;
}

@media screen and (min-width: 300px) {
  .borderLine {
    opacity: 0.3;
    border: 1px solid #000000;
    margin: 16px 0px;
    width: 100%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 300px) {
  .borderLine {
    opacity: 0.3;
    border: 1px solid #000000;
    margin: 16px 0px;
  }
}
@media screen and (max-width: 500px) {
  .faqImageCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .faqData {
    width: 100%;
  }
}
.faqImage {
  width: -webkit-fill-available;
  width: stretch;
}
.faqQuestion {
  margin-top: 1rem;
  font-weight: 1000;
}

.faq_bold {
  font-weight: 700;
}

.FAQ_link{
  color: blue;
}

.faqAnswer {
  padding-bottom: 10px;
}
.custom-file-input {
  border: solid rgb(201 204 207) 1px;
  border-radius: 4px 0px 0px 4px;
}
.custom-file-input::-webkit-file-upload-button {
  background-color: #f3f4f6;
  width: 100px;
  padding: 8px;
  font-size: 1.4rem;
  border-radius: 4px 0px 0px 4px;
  border: solid rgb(201 204 207) 1px;
  color: rgb(32 34 35);
}
.custom-file-input::before {
  display: inline-block;
  background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.radioButtonBorder {
  .Polaris-Choice__Label {
    font-size: 14px;
    padding-right: 10px;
  }
}
.radioButtonBorder .Polaris-RadioButton__Backdrop::before {
  background: #5ec127;
  border: 2px solid #5ec127;
  border-color: #5ec127;
}
.radioButtonBorder .Polaris-RadioButton__Backdrop::after {
  border: 2px solid #5ec127;
  border-color: #5ec127;
}
.p10 {
  padding: 10px 0px;
}
.removeMediaFile {
  display: flex;
  gap: 1rem;
  .Polaris-Icon {
    display: inline-flex;
  }
}

.reactour-helper-start {
  max-width: 25% !important;
}

.reactour-helper {
  max-width: 25% !important;
  display: flex;
  flex-direction: column-reverse;
}

.reactour-helper-end {
  max-width: 25% !important;
}

.productTourTitle {
  font-size: 1.375rem;
  font-weight: 500;
}

.productTourDescription {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6875rem;
  margin-top: 2rem;
}

.navigationButton {
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  gap: 1rem;
}

.textCenter {
  text-align: center;
}

.userFeedback {
  display: flex;
  justify-content: space-around;
  padding: 5rem 0rem 0rem;
}

.cursorFeedback {
  cursor: pointer;
}

.cursorFeedback:hover {
  color: #5ec127;
  svg :hover {
    fill: #5ec127;
  }
}

.customButtonWhite button {
  border: 1px solid #5ec127 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  .Polaris-Button__Text {
    font-weight: 600;
    color: #5ec127;
  }
}

.customButtonGreen button {
  color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  font-weight: 600;
  .Polaris-Button__Text {
    font-weight: 600;
  }
}

.tourStepsCount {
  position: absolute;
  color: #5ec127;
  font-size: 1.5rem;
  font-weight: 500;
}

.importChat-file-button {
  border: 1px solid #5ec127 !important;
  color: #5ec127;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  padding: 12px;
  font-size: 2rem;
  cursor: pointer;
}

.afterUploadMarkup {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 40px;

  .header {
    color: #6d7175;
    font-size: 2rem;
    font-weight: 600;
    line-height: 17px;
  }
  .note {
    color: #6d7175;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 17px;
  }

  .customButton {
    margin-top: 20px;
    button {
      background: #5ec127 !important;
      border: 1px solid #5ec127 !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
      border-radius: 4px !important;
      color: white;
    }
    .Polaris-Button__Content {
      font-size: 1.8rem !important;
      font-weight: 500;
      padding: 10px;
    }
  }
}

@media screen and (max-width: 500px) {
  .reactour-helper-start {
    max-width: 80% !important;
    width: 80% !important;
  }
  .reactour-helper {
    max-width: 80% !important;
    width: 80% !important;
    display: flex;
    flex-direction: column-reverse;
  }
  .reactour-helper-end {
    max-width: 80% !important;
    width: 80% !important;
  }
}

@media screen and (min-width: 501px) {
  .reactour-helper:after {
    content: '';
    position: absolute;
    top: 0px;
    left: -21px;
    border-style: solid;
    border-width: 24px 0px 0px 28px;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
  }
  .reactour-helper:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -23px;
    border-style: solid;
    border-width: 20px 0px 0px 23px;
    border-color: #000 transparent;
    display: block;
    width: 0;
    z-index: 0;
  }
}
