.card-size {
  height: 19em;
  overflow: hidden;
}

.condition-set-height {
  height: 55%;
}

.word-wrap {
  white-space: nowrap;
  width: 50px;
  visibility: visible;
}

// .Polaris-Page {
//     // margin: 8px 1000px;
//     padding: 0;
//     max-width: 85% !important;
//     // min-width: 80rem;
// }

.column {
    float: left;
    width: 30%;
    // padding: 10px;
    margin-left:0.5em;
    // height: 300px; /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  @media (min-width: 601px) and (max-width: 768px) {
    .column {
      width: 45%;
    }
  }
    
@media (max-width: 670px) {
  .column {
    width: 100%;
  }
}

@media (min-width: 901px) and (max-width: 1400px) {
  .column {
    width: 45%;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .column {
    width: 100%;
  }
}

.Send_Button:hover {
  background-color: white !important;
  color: #5ec127 !important;
  border: 1px solid #5ec127 !important;
}

.Send_Button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 1rem 1.6rem;
  background: var(--p-surface);
  border: 1px solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 2rem !important;
  background-color: #5ec127 !important;
  box-shadow: none !important;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
}
